import { Box, Button, Grid, Hidden, Link, makeStyles } from '@material-ui/core';
import CameraIcon from '@material-ui/icons/CameraAlt';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import mobilednifrente from '../../assets/mobile-dni-frente.svg';
import selfieImg from '../../assets/selfie.svg';
import eventTrackingService from '../../services/eventtracking.service';
import '../../styles/components/stepperValidation.scss';
import '../../styles/components/toc.scss';
import StepperValidation from '../stepper/StepperValidation';
import Toaster from './../../components/alerts/Toaster';
import Loader from './../../components/loader/Loader';
import documentService from './../../services/document.service';
import TocAutocaptureId from './TocAutocaptureId';
import TocAutocaptureSelfie from './TocAutocaptureSelfie';

const useStyles = makeStyles((theme) => ({
    captureStyle: {
        width: '100%',
        height: 'auto',
        maxHeight: 'auto',
        borderRadius: '8px',
        marginLeft: '30px',
    },
    captureStylePortrait: {
        width: '100%',
        height: 'auto',
        borderRadius: '8px',
        [theme.breakpoints.down('sm')]: {
            width: 'auto',
            maxHeight: '280px',
            maxWidth: '250px',
        },
        marginTop: '30px',
        marginBottom: '30px',
    },
    imgSelfieMargin: {
        marginTop: '50px',
        marginBottom: '50px',
    },
}));

const CaptureTypes = {
    RQDNIfrente: 'front',
    RQDNIdorso: 'back',
    RQFoto: 'selfie',
};

export const DocumentUploader = (props) => {
    const { title, hintInfo, document, hashKey, onDocumentUploaded, onHandleNext, onGetToken, previousToken, activeStep } = props;
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('Se produjo un error');
    const [token, setToken] = useState(null);
    const [imgSrc, setImgSrc] = useState(null);
    const { t } = useTranslation();
    const classes = useStyles();
    const [loading, setLoading] = useState(null);
    const [loadingToken, setLoadingToken] = useState(false);
    const [xsValue, setXsValue] = useState(12);

    const [showLiveness, setShowLiveness] = useState(false);

    const [showTOC, setShowTOC] = useState(false);
    const [showAlternativeCapture, setShowAlternativeCapture] = useState(false);

    const timeoutHandler = useRef();

    useEffect(() => {
        const urlPathEvent = `/tarjetas-credito/selfie`;
        if (imgSrc) {
            eventTrackingService.trackContentViewEvent(`${urlPathEvent}-confirmacion`);
        } else {
            if (showLiveness) {
                eventTrackingService.trackContentViewEvent(`${urlPathEvent}-captura`);
            } else {
                eventTrackingService.trackContentViewEvent(`${urlPathEvent}-inicio`);
            }
        }
    }, [imgSrc, showLiveness]);

    const getToken = async () => {
        setLoadingToken(true);
        try {
            const newToken = (await documentService.getUploadToken(hashKey)).data;
            setToken(newToken);
            setImgSrc(null);
            onDocumentUploaded(null);

            onGetToken(newToken);
        } catch (error) {
            setMessage(error.response.data);
            setOpen(true);
        } finally {
            setLoadingToken(false);
        }
    };

    useEffect(() => {
        if (previousToken) {
            setToken(previousToken);
        }
    }, []);

    useEffect(() => {
        if (document && !token && !previousToken) {
            getToken();
        }
    }, [document, document.name]);

    const [listener, setListener] = useState(null);

    function useMedia(query) {
        const [matches, setMatches] = useState(window.matchMedia(query).matches);

        useEffect(() => {
            if (!showTOC) {
                let media = window.matchMedia(query);
                if (media.matches !== matches) {
                    setMatches(media.matches);
                }

                if (media.matches) {
                    setXsValue(12);
                } else {
                    setXsValue(12);
                }
                setListener(() => setMatches(media.matches));
                media.addListener(listener);
            }
        }, [query]);

        return matches;
    }

    const matches = useMedia('(orientation: landscape)');

    const uploadFile = async (file, b64CaptureFile, uploadMethod) => {
        setShowTOC(false);
        setLoading(true);
        await documentService
            .uploadDocument(hashKey, document.name, token, file)
            .then(() => {
                setImgSrc(b64CaptureFile);
                onDocumentUploaded(document, uploadMethod);
                setLoading(false);
            })
            .catch((error) => {
                setImgSrc(null);
                setShowTOC(false);
                setShowAlternativeCapture(false);
                clearTimeout(timeoutHandler.current);
                onDocumentUploaded(null);
                setLoading(false);
                if (error.response.data) {
                    const { errorCode } = error.response.data;
                    switch (errorCode) {
                        case 'DOCUMENT_NUMBER_MISMATCH':
                            // Vamos a obviar la validacion si el documento no coincide.
                            onDocumentUploaded(document);
                            break;

                        case 'DOCUMENT_CANNOT_READ_BARCODE':
                            // track the barcode not found event
                            eventTrackingService.trackEvent('Barcode_not_found', uploadMethod, null, 0);

                            setMessage(t(`BAR_CODE_VALIDATION_ERROR.DOCUMENT_CANNOT_READ_BARCODE.DESCRIPTION`));
                            setOpen(true);
                            break;

                        case 'DOCUMENT_INCORRECT_BARCODE':
                            setMessage(t(`BAR_CODE_VALIDATION_ERROR.DOCUMENT_INCORRECT_BARCODE.DESCRIPTION`));
                            setOpen(true);
                            break;

                        default:
                            setMessage(t(`RENAPER_VALIDATION_ERROR.${errorCode}.DESCRIPTION`));
                            setOpen(true);
                    }
                } else {
                    setMessage(t(`RENAPER_VALIDATION_ERROR.${error.response.status}.DESCRIPTION`));
                    setOpen(true);
                }
            });
    };

    const ShowToaster = (props) => {
        return <Toaster elevation={6} variant="filled" {...props} />;
    };

    const dataURItoBlob = (dataURI) => {
        var byteString = atob(dataURI.split(',')[1]);
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    };

    const handleCapture = (target) => {
        if (target.files) {
            if (target.files.length > 0) {
                const file = target.files[0];
                uploadFile(file, URL.createObjectURL(file), 'Manual');
            }
        }
    };

    const handleSuccess = (captureToken, b64CaptureFile) => {
        uploadFile(dataURItoBlob(b64CaptureFile), b64CaptureFile, 'TOC');
        clearTimeout(timeoutHandler.current);
    };

    const handleShowTOC = () => {
        setImgSrc(null);
        onDocumentUploaded(null);
        setShowTOC(true);
        setShowAlternativeCapture(false);
    };

    const handleShowTOCLiveness = () => {
        handleShowTOC();
        setShowLiveness(true);
    };

    const handleRecapture = () => {
        // TOC doesnt allow to get a new image on the same session
        getToken();
        handleShowTOC();
    };

    const handleAlternativeCapture = () => {
        setShowAlternativeCapture(true);
    };

    const handleFailure = (errorCode) => {
        eventTrackingService.trackTocError(
            errorCode,
            t('CAPTURE_ERRORS.' + errorCode),
            CaptureTypes[document.name] !== 'selfie' ? 'autocapture' : 'liveness'
        );
        switch (errorCode) {
            case 'EXPIRED_TOKEN':
            case '402': // time limit exceeded
                getToken();
                break;
            case '405': // session expired
                getToken();
                break;
            case '403': // cancelled by user
            case '451': // cancelled by user
                setShowTOC(false);
                break;
            case '407': // camara no disponible
            case '409': // No hay permiso de cámara
                console.error(t('CAPTURE_ERRORS.' + errorCode));
                break;
            default:
                setShowAlternativeCapture(false);
                setShowTOC(false);
                setMessage(t('CAPTURE_ERRORS.' + errorCode));
                setOpen(true);
                break;
        }
    };

    const handleToasterClose = () => {
        setOpen(false);
    };

    const hiddenFileInput = React.useRef(null);

    const handleInputFileClick = (event) => {
        hiddenFileInput.current.click();
    };

    const handleInputFileChange = (event) => {
        handleCapture(event.target);
    };

    const CaptureDNI = () => {
        return !imgSrc ? (
            showTOC ? (
                showAlternativeCapture ? (
                    <div className="">
                        <Button onClick={handleInputFileClick} variant="contained" color="primary">
                            Cargar foto manualmente
                        </Button>
                        <input
                            accept="image/*"
                            ref={hiddenFileInput}
                            id="icon-button-file"
                            type="file"
                            capture="environment"
                            onChange={handleInputFileChange}
                            style={{ display: 'none' }}
                        />
                    </div>
                ) : (
                    <>
                        <div className="center tocContainer">
                            <div className="toc">
                                <Link
                                    component="button"
                                    variant="body2"
                                    style={{ color: 'white' }}
                                    onClick={handleAlternativeCapture}
                                    className="center width-100-per button-toc-alternative">
                                    ¿No Podés capturar la foto? Hacé click aqui
                                </Link>

                                {
                                    <TocAutocaptureId
                                        className=""
                                        captureType={CaptureTypes[document.name]}
                                        session={token}
                                        onHandleInputFileChange={handleInputFileChange}
                                        onSuccess={handleSuccess}
                                        onFailure={handleFailure}
                                        dniType={props.newDni ? 'ARG2' : 'ARG1'}
                                    />
                                }
                            </div>
                        </div>
                    </>
                )
            ) : (
                <Grid item xs={12} lg={4} className="mt-4">
                    <div className="center">
                        <img src={mobilednifrente} id="oldDni" alt=""></img>
                    </div>
                    <div className="startButton center center mt-2 mb-footer">
                        <Button onClick={handleShowTOC} className="width-100-per width-80-res" variant="contained" color="primary">
                            Capturar foto
                        </Button>
                    </div>
                </Grid>
            )
        ) : (
            <Grid item xs={12} lg={4}></Grid>
        );
    };

    const CaptureSelfie = () => {
        return !imgSrc ? (
            showTOC && showLiveness ? (
                <div className="center tocContainer">
                    <div className="toc">
                        <TocAutocaptureSelfie session={token} onSuccess={handleSuccess} onFailure={handleFailure} />
                    </div>
                </div>
            ) : (
                <>
                    <Grid item container>
                        <Grid item xs={12} lg={4} className="mt-4">
                            <div className="center">
                                <img src={selfieImg} id="oldDni" alt="" width="60px" className={classes.imgSelfieMargin} />
                            </div>
                        </Grid>
                        <Grid item container xs={xsValue}>
                            <Grid item container xs={xsValue} className="mb-2">
                                {hintInfo}
                            </Grid>
                        </Grid>
                        <Hidden smUp>
                            <Box m={6} />
                        </Hidden>
                    </Grid>
                    <Grid item container>
                        <div className="validationIdentityButton center mt-2 mb-footer">
                            <Button
                                onClick={handleShowTOCLiveness}
                                className="width-100-per width-90-res"
                                variant="contained"
                                color="primary"
                                startIcon={<CameraIcon />}
                                disabled={loading || loadingToken}>
                                Realizar prueba de vida
                            </Button>
                        </div>
                    </Grid>
                </>
            )
        ) : null;
    };

    return (
        <>
            <Loader loading={loading || loadingToken} />
            <Grid item container xs={12}>
                <StepperValidation activeStep={activeStep}></StepperValidation>
            </Grid>
            {document && token && (
                <>
                    <Grid item container xs={12} alignItems="center">
                        <p className="fs-20 fw-700">{title} </p>
                        <div className="mt-1"></div>
                    </Grid>

                    <Grid item container style={{ zIndex: 100 }} xs={xsValue} justifyContent="center" alignItems="center">
                        {!imgSrc && CaptureTypes[document.name] !== 'selfie' && <CaptureDNI />}
                        {!imgSrc && CaptureTypes[document.name] === 'selfie' && <CaptureSelfie />}

                        {imgSrc && (
                            <div className="">
                                <img className={classes.captureStylePortrait} src={imgSrc} alt={document.name} />
                            </div>
                        )}
                    </Grid>

                    {imgSrc && (
                        <>
                            <Grid item xs={xsValue}>
                                {hintInfo}
                            </Grid>
                            <Grid item xs={xsValue}>
                                <Link
                                    component="button"
                                    variant="body2"
                                    color="secondary"
                                    style={{ marginTop: '20px', fontSize: '16px', fontWeight: '700' }}
                                    onClick={handleRecapture}
                                    className="center width-100-per">
                                    Capturar nuevamente
                                </Link>
                            </Grid>
                            <Hidden smUp>
                                <Box m={7} />
                            </Hidden>
                        </>
                    )}

                    <Grid item xs={xsValue} className="">
                        {imgSrc && (
                            <div className="validationIdentityButton center mt-2 mb-footer">
                                <Button
                                    onClick={onHandleNext}
                                    variant="contained"
                                    color="primary"
                                    className="width-100-per width-90-res"
                                    startIcon={<CameraIcon />}
                                    disabled={loading || loadingToken}>
                                    Continuar
                                </Button>
                            </div>
                        )}
                    </Grid>

                    {open && (
                        <div className="mt-4">
                            <ShowToaster open={open} textToShow={message} type="error" handleToasterClose={handleToasterClose} />
                        </div>
                    )}
                </>
            )}
        </>
    );
};
