import { Box, Button, Grid, makeStyles, Modal } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Toaster from '../components/alerts/Toaster';
import useCurrentTask from '../components/bpm/useCurrentTask';
import ContinueMobile from '../components/deviceCommunication/continueMobile';
import CustomerDataUserInputs from '../components/userInputs/CustomerDataUserInputs';
import useCreditCardRequest from '../hooks/useCreditCardRequest';
import creditCardRequestService from '../services/creditCardRequest.service';
import eventTrackingService from '../services/eventtracking.service';
import './../styles/components/customerDataInput.scss';
import MacroStepPage from './shared/MacroStepPage';

export const AffidavitDataInput = (props) => {
    const { hashKey } = props.match.params;
    const { taskDefinitionKey } = props;
    const [userInputs, setUserInputs] = useState({});
    const [open, setOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const { creditCardRequest } = useCreditCardRequest(hashKey);
    const task = useCurrentTask(hashKey, taskDefinitionKey);
    const { t } = useTranslation();
    const [modalStyle] = React.useState(getModalStyle);
    const { handleSubmit, ...methods } = useForm();

    useEffect(() => {
        eventTrackingService.trackContentViewEvent('/tarjetas-credito/declaracion-jurada');
    }, []);

    function getModalStyle() {
        const top = 50;
        const left = 50;

        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }

    const useStylesModal = makeStyles((theme) => ({
        paper: {
            position: 'absolute',
            width: 300,
            backgroundColor: theme.palette.background.paper,
            borderRadius: 5,
            padding: theme.spacing(2, 2, 3),
        },
    }));

    const useStylesModalText = makeStyles(() => ({
        ulStyle: {
            padding: '20px !important',
        },
        modalText: {
            fontSize: '1rem',
            color: '#000000',
            textAlign: 'justify',

            fontWeight: '700',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 1.5,
            letterSpacing: 'normal',
        },
        actionBox: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            marginTop: '20px',
        },
    }));

    const classesModalText = useStylesModalText();
    const classesModal = useStylesModal();

    const validateAffidavit = () => {
        let msg = [];
        if (userInputs.PtllaPEP === 'true') {
            msg.push('Alcanzado dentro de la nómina de Personas Expuestas Políticamente');
        }
        if (userInputs.PtllaOCDE === 'true') {
            msg.push('OCDE (CRS)');
        }

        if (userInputs.PtllaSujetoObligadoUIF === 'true') {
            msg.push('Sujeto Obligado ante la UIF');
        }

        if (userInputs.PtllaUSCitizenFATCA === 'true') {
            msg.push('US Person - FATCA');
        }

        setMessages(msg);
        return msg.length === 0;
    };

    const handleNextPressed = (data) => {
        if (!validateAffidavit()) {
            setOpenModal(true);
            return;
        }
        handleConfirmAffidavit();
    };

    const handleConfirmAffidavit = async () => {
        try {
            await creditCardRequestService.setCustomerAffidavitData(hashKey, userInputs);
            task.completeTask();
        } catch (error) {
            if (error.response && error.response.data) {
                const { properties, errorCode } = error.response.data;
                const { fieldsToCompleted } = properties;
                if (errorCode === 'ALL_FIELDS_REQUIERED_MUST_BE_COMPLETED') {
                    var msg = t(`WORKFLOW_ERROR.${errorCode}.DESCRIPTION`);
                    fieldsToCompleted.map((field) => (msg += ' ' + t(`FIELDS.${field}`)));
                    setMessage(msg);
                    setOpen(true);
                }
            } else {
                console.error(error);
            }
        }
    };

    const handleComplete = (userInputFilled) => {
        setUserInputs(userInputFilled);
    };

    const ShowToaster = (props) => {
        return <Toaster elevation={6} variant="filled" {...props} />;
    };

    const handleToasterClose = () => {
        setOpen(false);
    };

    const handleContinue = () => {
        setOpenModal(false);
        handleConfirmAffidavit();
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const body = (
        <div style={modalStyle} className={classesModal.paper}>
            <p className="fs-16 fw-500">Seleccionaste que estás alcanzado por las regulaciones:</p>
            <ul className={classesModalText.ulStyle}>
                {messages.map((message, index) => (
                    <li key={index} className={classesModalText.modaltext} id="simple-modal-description">
                        {message}
                    </li>
                ))}
            </ul>
            <p className="fs-16 fw-700">¿Confirmás tu selección?</p>

            <div className={classesModalText.actionBox}>
                <Button onClick={handleCloseModal} className="" color="primary">
                    Cancelar
                </Button>
                <Button onClick={handleContinue} className="" variant="contained" color="primary">
                    Confirmar
                </Button>
            </div>
        </div>
    );

    return (
        <MacroStepPage
            headerLead="Tu Tarjeta de Credito está casi lista"
            headerTitle="Completá la declaración jurada"
            hashKey={hashKey}
            task={task}
            taskDefinitionKey={taskDefinitionKey}
            backToText={isMobile ? 'Selección de Oferta' : undefined}
            hideStepper={true}
            stepNumber={3}
            totalSteps={4}>
            {!isMobile ? (
                <ContinueMobile hashKey={hashKey} />
            ) : (
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(handleNextPressed)}>
                        <Grid item container className="mb-40-res">
                            {creditCardRequest && (
                                <CustomerDataUserInputs
                                    stepName={taskDefinitionKey}
                                    hashKey={creditCardRequest.hashKey}
                                    onComplete={handleComplete}
                                />
                            )}
                        </Grid>

                        <Grid item container direction="row" justify="center" alignItems="center" xs={12}>
                            <Grid item xs={12}>
                                <Box className="mt-5"></Box>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={4}>
                                <div className="mb-footer validationIdentityButton center mt-20-web">
                                    <Button className="width-100-per width-90-res" type="submit" variant="contained" color="primary">
                                        Siguiente
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                        <ShowToaster open={open} textToShow={message} type="error" handleToasterClose={handleToasterClose} />
                        <Modal
                            open={openModal}
                            onClose={handleCloseModal}
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description">
                            {body}
                        </Modal>
                    </form>
                </FormProvider>
            )}
        </MacroStepPage>
    );
};
