import { Box, Button, Card, CardActionArea, CardContent, Grid, Hidden, Link, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import checkIcon from '../assets/check-icon.svg';
import Toaster from '../components/alerts/Toaster';
import useCurrentTask from '../components/bpm/useCurrentTask';
import ContinueMobile from '../components/deviceCommunication/continueMobile';
import useCreditCardRequest from '../hooks/useCreditCardRequest';
import creditCardRequestService from '../services/creditCardRequest.service';
import eventTrackingService from '../services/eventtracking.service';
import '../styles/components/deliveryInfoInputPage.scss';
import './../styles/components/customerDataInput.scss';
import MacroStepPage from './shared/MacroStepPage';

export const DeliveryInfoInput = (props) => {
    const { hashKey } = props.match.params;
    const { taskDefinitionKey } = props;
    const [validForm, setValidForm] = useState(false);
    const [userInputs, setUserInputs] = useState({});
    const [open, setOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [hasAccount, setHasAccount] = useState(false);
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const { creditCardRequest } = useCreditCardRequest(hashKey);
    const task = useCurrentTask(hashKey, taskDefinitionKey);
    const { t } = useTranslation();
    const [cardMigration, setCardMigration] = useState(false);
    const [modalStyle] = React.useState(getModalStyle);
    const [typeAddress, setTypeAddress] = useState({
        customerAddress: null,
        branchAddress: false,
    });

    function getModalStyle() {
        const top = 50;
        const left = 50;

        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }

    const useStylesModal = makeStyles((theme) => ({
        paper: {
            position: 'absolute',
            width: 300,
            backgroundColor: theme.palette.background.paper,

            padding: theme.spacing(2, 2, 3),
        },
    }));

    const useStylesModalText = makeStyles(() => ({
        modalText: {
            fontSize: '11px',
            color: '#000000',
            textAlign: 'justify',

            fontWeight: 'bold',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 1.5,
            letterSpacing: 'normal',
        },
    }));

    const classesModalText = useStylesModalText();
    const classesModal = useStylesModal();

    useEffect(() => {
        eventTrackingService.trackContentViewEvent('/tarjetas-credito/seleccion-envio');
    }, []);

    useEffect(() => {
        setValidForm(true);
    }, [userInputs]);

    useEffect(() => {
        if (creditCardRequest) {
            setCardMigration(creditCardRequest.metadata.Origin === 'macro-migration-credit-card');

            creditCardRequestService
                .hasAnyAccount(creditCardRequest.id)
                .then((response) => {
                    setHasAccount(response.data);
                })
                .catch((error) => {
                    console.error(error.response);
                });
        }
    }, [creditCardRequest]);

    const handleNextPressed = (event) => {
        event.preventDefault();
        handleConfirmDeliveryInfo();
        eventTrackingService.trackEventWithData('tc_modalidad_envio', { metodo_envio: typeAddress.branchAddress ? 'sucursal' : 'domicilio' }, null);
    };

    const handleEditAddress = (event) => {
        event.preventDefault();
        task.goToTask('Message_goTo_edit_address');
    };

    const handleEditBranch = (event) => {
        event.preventDefault();
        task.goToTask('Message_goto_deliver_branch_office');
    };

    const handleConfirmDeliveryInfo = async () => {
        try {
            let deliveryAddress = typeAddress.branchAddress
                ? creditCardRequest.customerData.branchOfficeAccountAddress
                : creditCardRequest.customerAddress;
            await creditCardRequestService.setCustomerDeliveryInfo(hashKey, deliveryAddress, typeAddress.branchAddress);

            task.completeTask();
        } catch (error) {
            if (error.response && error.response.data) {
                const { properties, errorCode } = error.response.data;
                const { fieldsToCompleted } = properties;
                var msg = t(`WORKFLOW_ERROR.${errorCode}.DESCRIPTION`);
                setMessage(msg);
                setOpen(true);
            } else {
                console.error(error);
            }
        }
    };

    const handleComplete = (userInputFilled) => {
        setUserInputs(userInputFilled);
    };

    const ShowToaster = (props) => {
        return <Toaster elevation={6} variant="filled" {...props} />;
    };

    const handleToasterClose = () => {
        setOpen(false);
    };

    const selectDeliveryDestination = (deliveryAddress) => (e) => {
        if (deliveryAddress === 'customerAddress') {
            setTypeAddress({
                ...typeAddress,
                customerAddressStatus: 'active',
                branchAddress: false,
                branchAddressStatus: '',
            });
        } else if (deliveryAddress === 'branchAddress') {
            setTypeAddress({
                ...typeAddress,
                branchAddressStatus: 'active',
                branchAddress: true,
                customerAddressStatus: '',
            });
        }
    };

    return (
        <MacroStepPage
            headerLead={cardMigration ? 'Estás muy cerca de tener tu nueva tarjeta' : 'Tu tarjeta de crédito está casi lista'}
            headerLeadClassName="fw-600"
            headerTitle="Completá estos últimos datos"
            hashKey={hashKey}
            task={task}
            taskDefinitionKey={taskDefinitionKey}
            backToText={isMobile ? 'Declaración jurada' : undefined}
            hideStepper={true}
            stepNumber={3}
            totalSteps={4}>
            {!isMobile ? (
                <ContinueMobile hashKey={hashKey} />
            ) : (
                <>
                    <form onSubmit={handleNextPressed}>
                        <Grid item container className="mb-40-res">
                            {creditCardRequest && (
                                <div className="mt-3">
                                    <p className="fs-18 fw-500 mb-1">¿Dónde querés recibir tus Tarjetas?</p>

                                    <Grid item container direction="row" xs={12}>
                                        <Grid item container xs={12}>
                                            <Card className={`card-delivery-info ${typeAddress.customerAddressStatus}`}>
                                                <CardActionArea onClick={selectDeliveryDestination('customerAddress')}>
                                                    <CardContent>
                                                        <img src={checkIcon} alt="Icono de check" className="check-icon" />
                                                        <p className="fs-16 fw-700 title">Envío a domicilio</p>
                                                        <p className="fs-12 description mb-1">
                                                            <i>El envío será a tu domicilio declarado</i>
                                                        </p>
                                                        <p className="fs-14 fw-500">
                                                            {creditCardRequest.customerAddress}
                                                            {'    '}
                                                            {!cardMigration && (
                                                                <Link
                                                                    onClick={handleEditAddress}
                                                                    className="fs-14 fw-700 uppercase"
                                                                    color="secondary">
                                                                    Editar
                                                                </Link>
                                                            )}
                                                        </p>
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                        <Grid item container xs={12}>
                                            <Card className={`card-delivery-info ${typeAddress.branchAddressStatus}`}>
                                                <CardActionArea onClick={selectDeliveryDestination('branchAddress')}>
                                                    <CardContent>
                                                        <img src={checkIcon} alt="Icono de check" className="check-icon" />
                                                        <p className="fs-16 fw-700 title">Envío a sucursal Macro</p>
                                                        <p className="fs-12 description mb-1">
                                                            <i>El envío será a tu sucursal seleccionada</i>
                                                        </p>
                                                        <p className="fs-14 fw-500">
                                                            <span>{creditCardRequest.customerData.branchOfficeAccountAddress}</span>
                                                            {'    '}
                                                            {!hasAccount && !cardMigration && (
                                                                <Link onClick={handleEditBranch} className="fs-14 fw-700 uppercase" color="secondary">
                                                                    Editar
                                                                </Link>
                                                            )}
                                                        </p>
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </div>
                            )}
                        </Grid>

                        <Grid item container direction="row" justifyContent="center" alignItems="center" xs={12}>
                            <Hidden smUp>
                                <Grid item xs={12}>
                                    <Box className="mt-5"></Box>
                                </Grid>
                            </Hidden>

                            <Grid item xs={12} sm={12} md={6} lg={4}>
                                <div className="mb-footer validationIdentityButton center">
                                    <Button
                                        className="width-100-per width-90-res"
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={!typeAddress.customerAddressStatus && !typeAddress.branchAddressStatus}>
                                        Siguiente
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                        <ShowToaster open={open} textToShow={message} type="error" handleToasterClose={handleToasterClose} />
                    </form>
                </>
            )}
        </MacroStepPage>
    );
};
