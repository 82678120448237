import { Box, Button, Grid, Hidden, Link, makeStyles } from '@material-ui/core';
import CameraIcon from '@material-ui/icons/CameraAlt';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import documentService from '../../services/document.service';
import eventTrackingService from '../../services/eventtracking.service';
import '../../styles/components/stepperValidation.scss';
import '../../styles/components/toc.scss';
import Toaster from '../alerts/Toaster';
import Loader from '../loader/Loader';
import StepperValidation from '../stepper/StepperValidation';

const useStyles = makeStyles((theme) => ({
    captureStyle: {
        width: '100%',
        height: 'auto',
        maxHeight: 'auto',
        borderRadius: '8px',
        marginLeft: '30px',
    },
    captureStylePortrait: {
        width: '100%',
        height: 'auto',
        borderRadius: '8px',
        [theme.breakpoints.down('sm')]: {
            width: 'auto',
            maxHeight: '250px',
            maxWidth: '100%',
        },
    },
    imgDniMargin: {
        marginTop: '50px',
        marginBottom: '50px',
    },
}));

export const ManualDocumentUploader = (props) => {
    const { title, hintInfo, document, hashKey, onDocumentUploaded, onHandleNext, img, activeStep } = props;
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('Se produjo un error');
    const [imgSrc, setImgSrc] = useState(null);
    const { t } = useTranslation();
    const classes = useStyles();
    const [loading, setLoading] = useState(null);
    const [xsValue, setXsValue] = useState(12);
    const [listener, setListener] = useState(null);
    const eventPathTitle = useRef('');

    useEffect(() => {
        if (title) {
            if (title.includes('frente')) {
                eventPathTitle.current = 'dni-frente';
            }
            if (title.includes('dorso')) {
                eventPathTitle.current = 'dni-dorso';
            }
        }
    }, [title]);

    useEffect(() => {
        const urlPathEvent = `/tarjetas-credito/${eventPathTitle.current}`;
        if (imgSrc) {
            eventTrackingService.trackContentViewEvent(`${urlPathEvent}-confirmacion`);
        } else {
            eventTrackingService.trackContentViewEvent(`${urlPathEvent}-inicio`);
        }
    }, [imgSrc]);

    function useMedia(query) {
        const [matches, setMatches] = useState(window.matchMedia(query).matches);

        useEffect(() => {
            let media = window.matchMedia(query);
            if (media.matches !== matches) {
                setMatches(media.matches);
            }

            if (media.matches) {
                setXsValue(12);
            } else {
                setXsValue(12);
            }
            setListener(() => setMatches(media.matches));
            media.addListener(listener);
        }, [query]);

        return matches;
    }

    const uploadFile = async (file, b64CaptureFile, uploadMethod) => {
        const token = {
            session_Id: 'N/A',
        };
        setLoading(true);
        await documentService
            .uploadDocument(hashKey, document.name, token, file)
            .then(() => {
                setImgSrc(b64CaptureFile);
                onDocumentUploaded(document, uploadMethod);
                setLoading(false);
            })
            .catch((error) => {
                setImgSrc(null);
                onDocumentUploaded(null);
                setLoading(false);
                if (error.response.data) {
                    if (error.response.data.errorCode) {
                        if (error.response.data.errorCode === 'DOCUMENT_NUMBER_MISMATCH') {
                            // Vamos a obviar la validacion si el documento no coincide.
                            onDocumentUploaded(document);
                        } else {
                            setMessage(t(`RENAPER_VALIDATION_ERROR.${error.response.data.errorCode}.DESCRIPTION`));
                            setOpen(true);
                        }
                    } else {
                        setMessage(t(`RENAPER_VALIDATION_ERROR.${error.response.status}.DESCRIPTION`));
                        setOpen(true);
                    }
                } else {
                    if (error.response.data.errorCode === 'DOCUMENT_CANNOT_READ_BARCODE') {
                        setMessage(t(`BAR_CODE_VALIDATION_ERROR.DOCUMENT_CANNOT_READ_BARCODE.DESCRIPTION`));
                        setOpen(true);
                    } else {
                        if (error.response.data.errorCode === 'DOCUMENT_INCORRECT_BARCODE') {
                            setMessage(t(`BAR_CODE_VALIDATION_ERROR.DOCUMENT_INCORRECT_BARCODE.DESCRIPTION`));
                            setOpen(true);
                        } else {
                            setMessage(t(`RENAPER_VALIDATION_ERROR.${error.response.data.errorCode}.DESCRIPTION`));
                            setOpen(true);
                        }
                    }
                }
            });
    };

    const ShowToaster = (props) => {
        return <Toaster elevation={6} variant="filled" {...props} />;
    };

    const handleCapture = (target) => {
        if (target.files) {
            if (target.files.length > 0) {
                const file = target.files[0];
                uploadFile(file, URL.createObjectURL(file), 'Manual');
            }
        }
    };

    const handleRecapture = () => {
        setImgSrc(null);
        onDocumentUploaded(null);
    };

    const handleToasterClose = () => {
        setOpen(false);
    };

    const hiddenFileInput = React.useRef(null);

    const handleInputFileClick = (event) => {
        hiddenFileInput.current.click();
    };

    const handleInputFileChange = (event) => {
        handleCapture(event.target);
    };

    const CaptureDNI = () => {
        return !imgSrc ? (
            <>
                <div className="center">
                    <img src={img} id="oldDni" alt="" className={classes.imgDniMargin} />
                </div>

                <div className="center mt-2">
                    <input
                        accept="image/*"
                        ref={hiddenFileInput}
                        id="icon-button-file"
                        type="file"
                        capture="environment"
                        onChange={handleInputFileChange}
                        style={{ display: 'none' }}
                    />
                </div>
            </>
        ) : (
            <Grid item xs={12} lg={4}></Grid>
        );
    };

    return (
        <>
            <Loader loading={loading} />
            <Grid item container xs={12}>
                <StepperValidation activeStep={activeStep}></StepperValidation>
            </Grid>
            {document && (
                <>
                    <Grid item container xs={12} alignItems="center">
                        <p className="fs-20 fw-600">{title}</p>
                        <div className="mt-1"></div>
                    </Grid>

                    <Grid item container style={{ zIndex: 100 }} xs={xsValue} justify="center" alignItems="center">
                        {!imgSrc && <CaptureDNI />}

                        {imgSrc && (
                            <div className="" style={{ marginTop: '30px', marginBottom: '20px' }}>
                                <img className={classes.captureStylePortrait} src={imgSrc} alt={document.name} />
                            </div>
                        )}
                    </Grid>

                    {!imgSrc && (
                        <>
                            <Grid item container xs={xsValue} className="mb-2">
                                {hintInfo}
                            </Grid>
                            <Hidden smUp>
                                <Box m={3} />
                            </Hidden>
                            <Grid item container className="center mt-2 mb-footer validationIdentityButton center">
                                <Button
                                    onClick={handleInputFileClick}
                                    variant="contained"
                                    color="primary"
                                    className="width-100-per width-90-res"
                                    startIcon={<CameraIcon />}>
                                    Capturar foto
                                </Button>
                            </Grid>
                        </>
                    )}

                    {imgSrc && (
                        <>
                            <div className="mt-4"></div>
                            <Grid item xs={xsValue}>
                                <Link
                                    component="button"
                                    variant="body2"
                                    color="secondary"
                                    style={{ marginTop: '20px', fontSize: '16px', fontWeight: '600' }}
                                    onClick={handleRecapture}
                                    className="center width-100-per">
                                    Capturar nuevamente
                                </Link>
                            </Grid>
                            <Hidden smUp>
                                <Box m={6} />
                            </Hidden>
                        </>
                    )}

                    <Grid item xs={xsValue} className="">
                        {imgSrc && (
                            <Grid item container className="center mt-2 mb-footer validationIdentityButton center">
                                <Button onClick={onHandleNext} variant="contained" color="primary" className="width-100-per width-90-res">
                                    Siguiente
                                </Button>
                            </Grid>
                        )}
                    </Grid>

                    {open && (
                        <div className="mt-4">
                            <ShowToaster open={open} textToShow={message} type="error" handleToasterClose={handleToasterClose} />
                        </div>
                    )}
                </>
            )}
        </>
    );
};
