import { Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import eventTrackingService from '../../services/eventtracking.service';
import rejectedImageUrl from './../../assets/rejected.svg';
import './../../styles/components/error.scss';
import './../../styles/components/rejectedPage.scss';
import MacroPage from './../shared/MacroPage';

const useStyles = makeStyles(() => ({
    titleText: {
        fontFamily: 'Titillium Web',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: 'normal',
        color: '#092F57',
    },
    mainText: {
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: 'normal',
        color: '#5C5C5C',
    },
    callToActionText: {
        fontFamily: 'Titillium Web',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: 'normal',
    },
}));

export const Rejected = (props) => {
    const { history } = props;
    const { t } = useTranslation();
    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();
    const [rejectionCode, setRejectionCode] = useState(props.match.params.rejectedReasonCode);

    useEffect(() => {
        eventTrackingService.trackContentViewEvent('/tarjetas-credito/oferta-no-disponible');
    }, []);

    const getCallToActionParams = (rejectionCode) => {
        let params = null;
        switch (rejectionCode) {
            case 'REJECTED_BY_BESMART':
                params = {
                    translationPrefix: 'REJECTED.REJECTED_BY_BESMART.CALL_TO_ACTION',
                    url: 'https://macro.com.ar',
                    backgroundClassName: 'accountOpeningCallToActionBackground',
                    backgroundClassNameMobile: 'backgroundStyleMobile accountOpeningCallToActionBackgroundMobile',
                };
                break;
            default:
                params = null;
        }
        return params;
    };

    const handleBack = (event) => {
        event.preventDefault();
        history.push({ pathname: `/customerIdentification`, search: history.location.search });
    };

    const handleClickMainAction = () => {
        window.open(
            t(
                'REJECTED.' + props.match.params.rejectedReasonCode + '.LINK_MAIN_ACTION',
                'https://agenda.macro.com.ar/new?organization=5e8c9c11aacca63c310d4548'
            )
        );
    };
    const handleClickSecondaryAction = () => {
        window.open(
            t('REJECTED.' + props.match.params.rejectedReasonCode + '.LINK_SECONDARY_ACTION', 'https://www.macro.com.ar/buscador-cajeros-sucursales')
        );
    };

    const rejectedImage = <img height="163px" src={rejectedImageUrl} alt="" className="mb-4"></img>;
    const rejectedImageMobile = <img height="134px" src={rejectedImageUrl} alt="" className="mb-4"></img>;

    return (
        <>
            <MacroPage callToAction={getCallToActionParams(rejectionCode)} hideBackground={true}>
                <Grid container style={{ marginBottom: '40px' }}>
                    <Grid item xs={1} md={getCallToActionParams(rejectionCode) ? 1 : 3}></Grid>
                    <Grid item xs={10} md={getCallToActionParams(rejectionCode) ? 10 : 6} container direction="row" alignItems="center">
                        <Grid item xs={12} style={{ marginTop: '50px' }}>
                            {smDown ? rejectedImageMobile : rejectedImage}
                        </Grid>
                        <Grid item xs={12}>
                            <p className={classes.titleText}>{t('REJECTED.' + rejectionCode + '.TITLE')}</p>
                        </Grid>
                        <Grid item className="mt-4" xs={12}>
                            <p className={classes.mainText}>{t('REJECTED.' + rejectionCode + '.DESCRIPTION', 'Causa de Rechazo inesperada')}</p>
                            {!getCallToActionParams(rejectionCode) && (
                                <p className="fs-16 fw-600 mt-4 text-align-center">
                                    {t('REJECTED.' + rejectionCode + '.CALL_TO_ACTION_TITLE', 'Podés sacar un turno online para agilizar una visita')}
                                </p>
                            )}
                        </Grid>
                    </Grid>
                    {!getCallToActionParams(rejectionCode) && (
                        <Grid item xs={12}>
                            <div className="rejectButton center fixed-bottom-padding">
                                <Button className="flex center" variant="contained" color="primary" onClick={handleClickMainAction}>
                                    {t('REJECTED.' + rejectionCode + '.MAIN_ACTION', 'Sacar un turno online')}
                                </Button>
                                <Button className="flex center" variant="outlined" color="primary" onClick={handleClickSecondaryAction}>
                                    {t('REJECTED.' + rejectionCode + '.SECONDARY_ACTION', 'Ver sucursal más cercana')}
                                </Button>
                            </div>
                        </Grid>
                    )}
                </Grid>
            </MacroPage>
        </>
    );
};
