import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Hidden, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Toaster from '../components/alerts/Toaster';
import useCurrentTask from '../components/bpm/useCurrentTask';
import ContinueMobile from '../components/deviceCommunication/continueMobile';
import Loader from '../components/loader/Loader';
import AcceptContractCheckbox from '../components/login/AcceptContractCheckbox';
import AcceptMigrationContractCheckbox from '../components/login/AcceptMigrationContractCheckbox';
import { numberToCurrencyText } from '../components/utils/numberUtils';
import useCreditCardRequest from '../hooks/useCreditCardRequest';
import { creditCardRequestService } from '../services/creditCardRequest.service';
import productService from '../services/product.service';
import '../styles/components/disclosurePage.scss';
import MacroStepPage from './shared/MacroStepPage';

import verificationCompleteImage from '../assets/email_verified.svg';
import { footnote, montoBonificado100, montoBonificado50, precioPaquete, showTerms } from '../components/utils/termsAndConditionsUtils';
import documentService from '../services/document.service';
import eventTrackingService from '../services/eventtracking.service';

const useStyles = makeStyles({
    dialog: {
        position: 'absolute',
        top: 0,
    },
});

export const DisclosurePage = (props) => {
    const { hashKey } = props.match.params;
    const theme = useTheme();
    const classes = useStyles();
    const matchesSmSize = useMediaQuery(theme.breakpoints.down('sm'));
    const [message, setMessage] = useState(null);
    const [open, setOpen] = useState(false);
    const [disclosureSigned, setDisclosureSigned] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [loading, setLoading] = useState(null);
    const [selectedProductDetails, setSelectedProductDetails] = useState(null);
    const [imgSrc, setImgSrc] = useState(null);
    const [termsId, setTermsId] = useState(null);
    const [openPopup, setOpenPopup] = useState(false);
    const { t } = useTranslation();
    const task = useCurrentTask(hashKey, props.taskDefinitionKey);
    const { creditCardRequest } = useCreditCardRequest(hashKey);
    const [termsVisible, setTermsVisible] = useState(false);
    const [showDisclosureLoader, setShowDisclosureLoader] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [cardMigration, setCardMigration] = useState(false);
    const { state } = useLocation();

    useEffect(() => {
        let openTimeoutId = null;
        let closeTimeoutId = null;
        if (state && state.emailVerified) {
            openTimeoutId = setTimeout(() => {
                setDialogOpen(true);
            }, 1000);
            closeTimeoutId = setTimeout(() => {
                setDialogOpen(false);
            }, 6000);
        }
        return () => {
            if (openTimeoutId) {
                clearTimeout(openTimeoutId);
            }
            if (closeTimeoutId) {
                clearTimeout(closeTimeoutId);
            }
        };
    }, []);

    useEffect(() => {
        eventTrackingService.trackContentViewEvent('/tarjetas-credito/datos-confirmacion');
    }, []);

    useEffect(() => {
        if (creditCardRequest) {
            setCardMigration(creditCardRequest.metadata.Origin === 'macro-migration-credit-card');
            setDisclosureSigned(creditCardRequest.hasSigned);
            setSelectedProduct(creditCardRequest.offerOptions[0]);
        }
    }, [creditCardRequest]);

    useEffect(() => {
        async function getProductDetails(productCode) {
            try {
                const selProd = await productService.getProductByModel(productCode);
                setSelectedProductDetails(selProd.data.productDetails);
            } catch (error) {
                console.error(error.response);
            }
        }

        if (selectedProduct) {
            setImgSrc(require(`./../assets/tc_paquetes/${selectedProduct.productCode.toLowerCase()}.png`));
            getProductDetails(selectedProduct.productCode);
        }
    }, [selectedProduct]);

    useEffect(() => {
        if (selectedProductDetails) {
            setTermsVisible(true);
        }
    }, [selectedProductDetails]);

    const ShowToaster = (props) => {
        return <Toaster elevation={6} variant="filled" {...props} />;
    };

    const handleAcceptTermsChange = (accepted, termsId) => {
        setTermsId(termsId);
        setDisclosureSigned(accepted);
    };

    const completeSignature = async () => {
        setLoading(true);
        try {
            await creditCardRequestService.finishRequestWithoutSign(creditCardRequest.id);

            await task.completeTask();
        } catch (error) {
            if (error.response) {
                if (error.response.data) {
                    const { errorCode } = error.response.data;
                    setMessage(t(`WORKFLOW_ERROR.${errorCode}.DESCRIPTION`) || 'Error durante la ejecución');
                    setOpen(true);
                } else {
                    console.error('Error: ' + error);
                }
            }
        } finally {
            setLoading(false);
        }
    };

    const handleFinishClicked = (event) => {
        event.preventDefault();
        completeSignature();
    };

    const handleToasterClose = () => {
        setOpen(false);
    };

    const handleClickOpenPopup = () => {
        setOpenPopup(true);
    };

    const handleClosePopup = () => {
        setOpenPopup(false);
    };

    const handleDisclosureDownloadClicked = (event) => {
        event.preventDefault();

        if (!showDisclosureLoader) {
            setShowDisclosureLoader(true);
            documentService
                .openDisclosureDocumentInNewTab(creditCardRequest.hashKey)
                .then(function (response) {
                    var url = window.URL.createObjectURL(response.data);
                    setShowDisclosureLoader(false);
                    window.open(url);
                })
                .catch((err) => {
                    console.error(err);
                    setShowDisclosureLoader(false);
                });
        }
    };

    const packageName = selectedProduct ? `${t('CREDIT_CARD_DESCRIPTION.' + selectedProduct.productCode + '.PACKAGE_SHORT')}` : null;

    const conditionsPopup = useMemo(
        () => (
            <>
                {creditCardRequest && selectedProduct && termsVisible && (
                    <Dialog onClose={handleClosePopup} aria-labelledby="help-dialog-title" open={openPopup}>
                        {/* Dialogo de condiciones del paquete seleccionado */}
                        <DialogTitle id="help-dialog-title" onClose={handleClosePopup} className="dialog-title-conditions">
                            Conocé las condiciones de bonificación de tu servicio
                        </DialogTitle>
                        <DialogContent dividers>
                            <p className="dialog-text">
                                Los primeros 12 meses son 100% bonificados y a partir del mes trece empezarás a pagar un valor de{' '}
                                {precioPaquete(selectedProductDetails)} (Costo de referencia a la fecha).
                            </p>
                            <p className="dialog-text">
                                Si cumplís con alguna de las siguientes condiciones podés continuar con el mantenimiento mensual sin costo:
                            </p>
                            <div>
                                <div className="condition-box">
                                    <p>
                                        Adhiriendo 3 Débitos Automáticos a tu Tarjeta de Crédito accedés a una bonificación de 3 meses adicionales,
                                        del 100% de tu servicio.
                                    </p>
                                </div>
                                <div className="condition-box">
                                    <p>
                                        Con un saldo promedio de {montoBonificado100(selectedProductDetails)} obtenés bonificación del 100% de tu
                                        servicio. (1)
                                    </p>
                                </div>
                                <div className="condition-box">
                                    <p>
                                        Con un saldo promedio de {montoBonificado50(selectedProductDetails)} obtenés bonificación del 50% de tu
                                        servicio. (1)
                                    </p>
                                </div>
                            </div>
                            <p className="disclaimer-note justify-terms" gutterBottom>
                                {footnote(selectedProduct)}
                                (1) Se suman los saldos diarios de las cuentas asociadas al servicio y se divide por la cantidad de días del mes y/o
                                el saldo de los plazos fijos vigentes a fin de mes.
                            </p>
                            <br />
                            <hr />
                            <br />
                            <p className="dialog-title-conditions">Términos Legales</p>
                            <br />
                            {showTerms(selectedProduct, selectedProductDetails, packageName)}
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" onClick={handleClosePopup} color="primary" style={{ width: '100%' }}>
                                Entendido
                            </Button>
                        </DialogActions>
                    </Dialog>
                )}
            </>
        ),
        [creditCardRequest, openPopup, selectedProduct, t]
    );

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    return (
        <MacroStepPage
            headerLead={''}
            headerTitle={''}
            task={task}
            taskDefinitionKey={props.taskDefinitionKey}
            backToText="Carga de datos personales"
            hideStepper={true}
            stepNumber={4}
            totalSteps={4}>
            {!isMobile ? (
                <ContinueMobile hashKey={hashKey} />
            ) : (
                <div className="width-100-per">
                    {creditCardRequest && selectedProduct && (
                        <>
                            <Loader loading={loading} />
                            <Dialog
                                classes={{
                                    paper: classes.dialog,
                                }}
                                keepMounted
                                open={dialogOpen}
                                onClose={handleDialogClose}
                                hideBackdrop={true}>
                                <DialogContent>
                                    <Grid container justifyContent="center" alignItems="center">
                                        <Grid item xs={12}>
                                            <Box display="flex" justifyContent="center" alignItems="center">
                                                <img className="mt-1" src={verificationCompleteImage} width="50px" alt="Email verificado"></img>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <p className="fw-700 fs-20 color-dark-blue text-align-center mt-1 mb-2">Hemos verificado tu email</p>
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                            </Dialog>
                            <div className="package-info-box">
                                <img src={imgSrc} alt="" className="credit-card-selected-img" />
                                {cardMigration ? (
                                    <p>
                                        Estás por aceptar una <b>tarjeta {t('CREDIT_CARD_DESCRIPTION.' + selectedProduct.productCode + '.NAME')}</b>{' '}
                                        por un monto de <b>{numberToCurrencyText(selectedProduct.OfferLimit)}.</b>
                                    </p>
                                ) : (
                                    <p>
                                        Estás por aceptar una <b>{t('CREDIT_CARD_DESCRIPTION.' + selectedProduct.productCode + '.NAME')}</b> con un
                                        límite de <b>{numberToCurrencyText(selectedProduct.OfferLimit)}</b>, asociada a un Servicio{' '}
                                        <b>{t('CREDIT_CARD_DESCRIPTION.' + selectedProduct.productCode + '.PACKAGE')}</b> 100% bonificado por 12
                                        meses.{' '}
                                    </p>
                                )}
                            </div>
                            <br />
                            <p>
                                <b>Vas a contar con:</b>
                            </p>
                            <ul className="credit-card-detail-list">
                                {Object.keys(creditCardRequest.offerSelected)
                                    .filter(
                                        (key) =>
                                            key.startsWith('Beneficio') &&
                                            creditCardRequest.offerSelected[key] !== null &&
                                            creditCardRequest.offerSelected[key].replace(/\s/g, '')
                                    )
                                    .sort()
                                    .map((element, index) => (
                                        <li key={index}>{creditCardRequest.offerSelected[element]}</li>
                                    ))}
                            </ul>
                            <br />
                            {!cardMigration && (
                                <p className="legal-text">También obtenés sin cargo una Caja de ahorro en pesos y una Tarjeta de Débito Macro.</p>
                            )}
                            <br />
                            {!cardMigration && (
                                <p className="legal-text">
                                    * Para obtener una Caja de Ahorro en Dólares sin costo adicional deberás solicitarla en sucursal.
                                </p>
                            )}
                            <br />
                            <Grid item xs={12}>
                                {cardMigration ? (
                                    <AcceptMigrationContractCheckbox
                                        onChange={handleAcceptTermsChange}
                                        onContractLinkClicked={handleDisclosureDownloadClicked}
                                    />
                                ) : (
                                    <AcceptContractCheckbox
                                        onChange={handleAcceptTermsChange}
                                        onContractLinkClicked={handleDisclosureDownloadClicked}
                                    />
                                )}
                                <Hidden smUp>
                                    <Box m={9} />
                                </Hidden>
                            </Grid>
                            <br />
                        </>
                    )}
                    <Grid container justifyContent="center" alignItems="center" className=" mt-2">
                        <Grid item xs={12} sm={12} md={6} lg={4} className="flex center">
                            <Box className="mt-4"></Box>
                            <div className="startButton center" style={{ flexDirection: 'column' }}>
                                <p className="tc-text-disclosure">
                                    {!cardMigration && (
                                        <>
                                            A partir del mes trece podes mantener la bonificación con alguna de las siguientes{' '}
                                            <Button
                                                variant="text"
                                                onClick={handleClickOpenPopup}
                                                color="secondary"
                                                className="link-text-button link-blue">
                                                condiciones
                                            </Button>
                                        </>
                                    )}
                                </p>
                                <Button
                                    className="width-100-per width-90-res"
                                    variant="contained"
                                    color="primary"
                                    onClick={handleFinishClicked}
                                    disabled={!disclosureSigned}>
                                    Confirmar
                                </Button>
                            </div>
                        </Grid>
                        {conditionsPopup}
                        <Grid item>
                            <ShowToaster open={open} textToShow={message} type="error" handleToasterClose={handleToasterClose} />
                        </Grid>{' '}
                    </Grid>
                </div>
            )}
        </MacroStepPage>
    );
};
