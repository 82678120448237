import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Hidden, TextField, Typography } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import { creditCardRequestService } from '../../services/creditCardRequest.service';
import eventTrackingService from '../../services/eventtracking.service';
import termService from '../../services/term.service';
import workflowService from '../../services/workflow.service';
import '../../styles/components/tabs.scss';
import { CustomDatePicker } from '../customInputs/CustomDatePicker';
import Loader from '../loader/Loader';
import { obfuscate } from '../utils/obfuscate';
import Toaster from './../../components/alerts/Toaster';
import AcceptGeneralTermsCheckbox from './AcceptGeneralTermsCheckbox';
import { CheckEmail } from './checkEmail/checkEmail';
import { CheckPhone } from './checkphone/checkPhone';
import { ApiContext } from './useLogin';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div>
            {value === index && (
                <Box p={3}>
                    <span>{children}</span>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        width: null,
        height: null,
    });

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowSize;
}

const DniMaskCustom = (props) => {
    const { inputRef, ...other } = props;

    return <MaskedInput {...other} ref={inputRef} mask={[/[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]} placeholderChar={'\u2000'} />;
};

const CuitMaskCustom = (props) => {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={inputRef}
            mask={[/[1-9]/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/]}
            guide={false}
            placeholderChar={'\u2000'}
        />
    );
};

DniMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

DniMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

CuitMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

export default function FullWidthTabs(props) {
    const size = useWindowSize();
    const width = size.width;
    const { request, setRequest, cuits, setCuits, pin, number, setNumber, email, setEmail, loading, setLoading } = useContext(ApiContext);
    const { clientNumber } = number;
    const { clientPin } = pin;
    const { clientEmail } = email;
    const [open, setOpen] = useState(false);
    const [dni, setDni] = useState('');
    const [manualCuit, setManualCuit] = useState('');
    const [manualFirstName, setManualFirstName] = useState('');
    const [manualLastName, setManualLastName] = useState('');
    const [birthDate, setBirthDate] = useState(null);
    const [dniError, setDniError] = useState(false);
    const [message, setMessage] = useState('');
    const [openPopup, setOpenPopup] = useState(false);
    const { t } = useTranslation();
    const [dniSelected, setDniSelected] = useState(false);
    const mediaMatch = window.matchMedia('(min-width: 500px)');
    const [matches, setMatches] = useState(mediaMatch.matches);
    const [marketingData, setMarketingData] = useState({});
    const [customerData, setCustomerData] = useState(undefined);
    const [optionCuitVisible, setOptionCuitVisible] = useState(false);
    const [checkPhoneVisible, setCheckPhoneVisible] = useState(false);
    const [checkEmailVisible, setCheckEmailVisible] = useState(false);
    const [checkTermsVisible, setCheckTermsVisible] = useState(false);
    const [requestButtonVisible, setrequestButtonVisible] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [termsId, setTermsId] = useState(null);
    const [manualCuitError, setManualCuitError] = useState(false);
    const [alternativeCuit, setAlternativeCuit] = useState(false);
    const [customerDataCompleted, setCustomerDataComplete] = useState(false);
    const [promoterInfo, setPromoterInfo] = useState(null);

    const analyticsStep = 2;

    const WIDTH_BREAKPOINT = 1100;

    useEffect(() => {
        const handler = (e) => setMatches(e.matches);
        mediaMatch.addListener(handler);
        return () => mediaMatch.removeListener(handler);
    });

    const styles = {
        container: (isFullWidth) => ({
            marginTop: isFullWidth ? '10px' : '12px',
            minWidth: isFullWidth ? '90%' : '100%',
            marginLeft: isFullWidth ? '10px' : '0px',
            maxWidth: '100%',
            width: isFullWidth ? '90%' : '100%',
        }),
    };

    useEffect(() => {
        if (!checkPhoneVisible && !checkEmailVisible && !alternativeCuit) {
            eventTrackingService.trackContentViewEvent('/tarjetas-credito/ingreso-dni');
        }
    }, [checkEmailVisible]);

    useEffect(() => {
        localStorage.removeItem('token');

        if (props.location.search) {
            const searchParams = new URLSearchParams(props.location.search);
            const trackingId = searchParams.get('trackingId');
            const promoter = searchParams.get('promoter');
            const o = searchParams.get('o');

            let filteredMarketingData = {};
            searchParams.forEach((value, key) => {
                if (!key.toLowerCase().startsWith('utm')) {
                    return;
                }
                filteredMarketingData[key] = searchParams.get(key);
            });
            setMarketingData({ _gl: eventTrackingService.getClientId(), ...filteredMarketingData });

            if (o) {
                searchParams.delete('o');
                history.replace({
                    search: searchParams.toString(),
                });
                setCustomerData(o);
            }

            if (promoter) {
                const promoterInfo = { name: promoter };
                if (promoter === 'ALPRESTAMO' && trackingId) {
                    promoterInfo.additionalData = {
                        trackingId: trackingId,
                    };
                }
                setPromoterInfo(promoterInfo);
            }
        }
    }, []);

    useEffect(() => {
        async function getDecryptedCustomerData() {
            setLoading(true);
            try {
                let customerDataList = (await creditCardRequestService.getDecryptedCustomerData(customerData)).data;
                setDni(customerDataList.idNumber);
                setOptionCuitVisible(false);
                setCheckPhoneVisible(false);
                setCheckEmailVisible(false);
                setrequestButtonVisible(false);
                setCuits([]);
                setDniError(false);
                fillCustomerInputs(customerDataList);
                fillPromoterInfo(customerDataList);
            } finally {
                setLoading(false);
            }
        }
        if (customerData && customerData.length > 1) {
            getDecryptedCustomerData();
        }
    }, [customerData]);

    useEffect(() => {
        if ((cuits && cuits.length > 0) || alternativeCuit) {
            setOptionCuitVisible(true);
            if (cuits.length === 1) {
                setRequest({
                    ...request,
                    cuit: cuits[0].id,
                });

                if (!alternativeCuit) {
                    confirmCuit(cuits[0].id);
                }
            }
        }
    }, [cuits]);

    const handleCustomerTaxIdChange = (e) => {
        setDniSelected(e.target.id !== null);
        setRequest({
            ...request,
            cuit: e.target.id,
            sex: '',
            name: e.target.value,
            birthday: null,
            email: null,
        });
    };

    const obtenerCuit = async () => {
        if (dni) {
            setLoading(true);
            try {
                const cuits = (await workflowService.getUserInputComboData(null, 'PtllaCUIT', 'stepPreCustomerDataDirect', dni)).data;
                setCuits(cuits);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                if (error.response) {
                    const { errorCode } = error.response.data;
                    if (errorCode === 'ERROR_GETTING_DATA_FROM_DATASOURCE') {
                        setAlternativeCuit(true);
                        setCuits([]);
                        setLoading(false);
                    } else {
                        setMessage(t(`WORKFLOW_ERROR.${errorCode}.DESCRIPTION`) || 'Error durante la ejecución');
                        setOpen(true);
                    }
                }
            }
        }
    };

    const fillCustomerInputs = async (customerData) => {
        setRequest({
            ...request,
            dni: customerData.idNumber,
            birthday: customerData.birthdate,
            email: null,
            cuit: customerData.taxIdNumber,
        });
        setEmail({
            ...setEmail,
            clientEmail: customerData.email,
        });
        setNumber({
            ...setNumber,
            clientNumber: customerData.cellphone,
        });
        eventTrackingService.setCustomerId(customerData.idNumber);
        setCuits([{ id: customerData.taxIdNumber }]);
        setManualFirstName(customerData.firstName);
        setManualLastName(customerData.lastName);
    };

    const fillPromoterInfo = async (customerData) => {
        if (!promoterInfo.additionalData) {
            promoterInfo.additionalData = {};
        }
        for (const key in customerData) {
            if (customerData.hasOwnProperty(key) && key.startsWith('promoter_data_')) {
                const newKey = key.replace('promoter_data_', '');
                promoterInfo.additionalData[newKey] = customerData[key];
            }
        }
        setPromoterInfo(promoterInfo);
    };

    const createLoanRequest = () => {
        const getCurrentTask = async (hashKey) => {
            try {
                const currentTask = (await workflowService.getCurrentTask(hashKey)).data;
                setLoading(false);
                history.push({ pathname: `/${currentTask.taskDefinitionKey}/${hashKey}`, search: history.location.search });
            } catch (error) {
                setLoading(false);
                if (error.response) {
                    const { errorCode } = error.response.data;
                    if (errorCode === 'NO_ACTIVE_TASKS') {
                        const creditCardRequest = (await creditCardRequestService.getCreditCardRequest(hashKey)).data;
                        switch (creditCardRequest.status) {
                            case 'REJECTED':
                                history.push({ pathname: `/rejected/${creditCardRequest.rejectionReasonCode}`, search: history.location.search });
                                break;
                            case 'FINALIZED':
                                history.push({ pathname: `/success/${hashKey}`, search: history.location.search });
                                break;
                            case 'READY_FOR_DELIVERY':
                                history.push({ pathname: `/success/${hashKey}`, search: history.location.search });
                                break;
                            case 'UNDER_REVIEW':
                                history.push({ pathname: `/success/${hashKey}`, search: history.location.search });
                                break;
                            default:
                                break;
                        }
                    } else {
                        setMessage(t(`WORKFLOW_ERROR.${errorCode}.DESCRIPTION`) || 'Error durante la ejecución');
                        setOpen(true);
                        throw error;
                    }
                }
            }
        };

        const startWorkflow = async () => {
            try {
                let customerName = `${manualLastName} ${manualFirstName}`.toUpperCase();
                let result = await creditCardRequestService.createRequest(
                    `${request.cuit}`,
                    `${dni}`,
                    customerName,
                    `${manualFirstName}`,
                    `${manualLastName}`,
                    `${request.sex}`,
                    `${request.birthday}`,
                    `${clientEmail}`,
                    `${clientNumber}`,
                    `${clientPin}`,
                    { _gl: eventTrackingService.getClientId(), ...marketingData },
                    promoterInfo
                );

                let header = result.headers;
                const creditCardRequest = result.data;
                localStorage.setItem('token', header.authorization);

                if (creditCardRequest.hashKey) {
                    getCurrentTask(creditCardRequest.hashKey);
                } else {
                    getCurrentTask(result.data.properties.hash);
                }
            } catch (error) {
                setLoading(false);
                if (error.response) {
                    const { cause, errorCode, properties } = error.response.data;
                    switch (errorCode) {
                        case 'ACTIVE_LOAN_REQUEST_SAME_COMMERCE':
                            history.push({ pathname: `/identityValidation/${properties.hash}`, search: history.location.search });
                            break;
                        case 'ACTIVE_LOAN_REQUEST_OTHER_CELLPHONE':
                            setMessage('Ya tenés una solicitud iniciada con otro celular. Por favor ingresá ese mismo número para continuar.');
                            setOpen(true);
                            break;
                        case 'PEN_CREDIT_OTHER_COMMERCE':
                            setMessage(cause || 'Ya tenés una solicitud activa en otro canal');
                            setOpen(true);
                            break;
                        case 'REQUEST_ACTIVE_RECENTLY':
                            setMessage('Ya tenés una solicitud activa. No podés solicitar una nueva tarjeta.');
                            setOpen(true);
                            break;
                        default:
                            setMessage(t(`WORKFLOW_ERROR.${errorCode}.DESCRIPTION`));
                            setOpen(true);
                            break;
                    }
                } else {
                    console.error(error);
                }
            }
        };

        const acceptTerms = async () => {
            try {
                await termService.acceptTerms(request.cuit, termsId);
                startWorkflow();
            } catch (error) {
                console.error(error.response.data);
                setLoading(false);
            }
        };

        setLoading(true);
        acceptTerms();
    };

    let history = useHistory();

    const handleManualCuitChange = (e) => {
        const cuit = e.target.value.trim();
        setManualCuitError(false);
        setManualCuit(cuit);
        setRequest({
            ...request,
            cuit: cuit.replaceAll(/-/g, ''),
        });
    };

    const handleFirstNameChange = (e) => {
        setManualFirstName(e.target.value);
    };

    const handleLastNameChange = (e) => {
        setManualLastName(e.target.value);
    };

    const handleDateChange = (e) => {
        if (e == 'Invalid Date') {
            setBirthDate(null);
            setRequest({
                ...request,
                birthday: null,
            });
        } else {
            setBirthDate(e);
            setRequest({
                ...request,
                birthday: e,
            });
        }
    };

    const CUIT_REGEX = /[1-9]\d-\d{8}-[1-9]/;

    const confirmCuit = (cuit) => {
        if (!cuit || (alternativeCuit && !manualCuit.match(CUIT_REGEX))) {
            setManualCuitError(true);
            return;
        }
        setOptionCuitVisible(false);
        setCheckPhoneVisible(false);
        setCheckEmailVisible(true);
        setrequestButtonVisible(false);

        if (customerData) {
            setCustomerDataComplete(true);
            handleCompleteEmail();
        }
    };

    const handlePinSent = (value) => {
        setCheckTermsVisible(value);
    };

    const handleClickOpenPopup = () => {
        setOpenPopup(true);
    };

    const handleClosePopup = () => {
        setOpenPopup(false);
    };

    const handleCompleteEmail = () => {
        setCheckPhoneVisible(true);
        setCheckTermsVisible(false);
        setrequestButtonVisible(true);
    };

    const handleEmailChanged = () => {
        setCheckPhoneVisible(false);
        setCheckTermsVisible(false);
        setrequestButtonVisible(false);
    };

    const handleAcceptTermsChange = (accepted, termsId) => {
        setTermsId(termsId);
        setTermsAccepted(accepted);
    };

    const isStartButtonDisabled = () => {
        return !termsAccepted || !clientNumber || !clientPin || clientPin.length < 4;
    };

    const handleValidateDNIClicked = (event) => {
        event.preventDefault();
        if (dni.length >= 7 && dni.length <= 8) {
            eventTrackingService.setCustomerId(dni);
            eventTrackingService.trackEvent('consulta de dni_tc', null, null, analyticsStep);
            obtenerCuit();
        } else {
            setDniError(true);
        }
    };

    const handleFormSubmitted = (event) => {
        event.preventDefault();
        if (optionCuitVisible) {
            confirmCuit(request.cuit);
        } else if (requestButtonVisible) {
            createLoanRequest();
        }
    };

    const handleDNIChange = (e) => {
        setDni(e.target.value.trim());
        setOptionCuitVisible(false);
        setCheckPhoneVisible(false);
        setCheckTermsVisible(false);
        setCheckEmailVisible(false);
        setrequestButtonVisible(false);
        setCuits([]);
        setDniError(false);

        setRequest({
            ...request,
            dni: e.target.value.trim(),
            birthday: null,
            email: null,
        });
    };

    const handleChangeDNIClicked = (e) => {
        setDni(null);
        setManualCuit(null);
        setManualFirstName(null);
        setManualLastName(null);
        setBirthDate(null);
        setOptionCuitVisible(false);
        setCheckPhoneVisible(false);
        setCheckTermsVisible(false);
        setCheckEmailVisible(false);
        setrequestButtonVisible(false);
        setTermsAccepted(false);
        setAlternativeCuit(false);
        setCuits([]);
        setRequest({
            ...request,
            dni: null,
            birthday: null,
            email: null,
        });
    };

    const ShowToaster = (props) => {
        return <Toaster elevation={6} variant="filled" {...props} />;
    };

    const handleToasterClose = () => {
        setOpen(false);
    };

    /**
     * Campos condicionales - van apareciendo a medida que se van aceptando o completando campos
     */

    const optionCuit = optionCuitVisible ? (
        <>
            {cuits.length > 1 && (
                <div className="cuitDescription mt-3">
                    <span className="fs-14 color-darker-gray">
                        <div className="select-cuit-desc-title">
                            <span>¿Cuál de estas personas sos? </span>
                            <Button variant="text" onClick={handleClickOpenPopup} color="dark-gray">
                                <HelpIcon style={{ zIndex: 999, height: '30px', fontSize: 24, color: 'dark-gray' }} />
                            </Button>
                        </div>
                        <Dialog onClose={handleClosePopup} aria-labelledby="help-dialog-title" open={openPopup}>
                            <DialogTitle id="help-dialog-title" onClose={handleClosePopup}>
                                Ayuda
                            </DialogTitle>
                            <DialogContent dividers>
                                <Typography gutterBottom>
                                    Encontramos más de una coincidencia para este DNI. Por seguridad, los nombres aparecen ofuscados.
                                </Typography>
                                <Typography>Ejemplo:</Typography>
                                <Typography style={{ fontWeight: 'bold' }}>Perez García Juan Pablo se verá como PER** ***** **** **BLO</Typography>
                                <Typography gutterBottom>Por favor, seleccioná quién sos para continuar.</Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button variant="contained" onClick={handleClosePopup} color="primary" style={{ width: '100%' }}>
                                    Entendido
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </span>
                    {cuits.map((cuit) => (
                        <>
                            <div className="separator mt-05 mb-05"></div>
                            <div key={cuit.id} value={cuit.id} className="flex">
                                <input
                                    onChange={handleCustomerTaxIdChange}
                                    type="radio"
                                    id={cuit.id}
                                    name="cuit"
                                    value={(cuit.id, cuit.descripcion)}
                                />

                                <div className="ml-03-per">
                                    <div className="fw-400 fs-15 color-black">{obfuscate(cuit.descripcion)}</div>
                                </div>
                            </div>
                        </>
                    ))}
                </div>
            )}{' '}
            {cuits.length < 1 && (
                <div>
                    <Grid container direction="row" justifyContent="flex-start" className="">
                        <Grid item xs={12} sm={8} lg={8} className="mt-2">
                            <TextField
                                id="standard-basic"
                                label="Ingresá tu CUIT sin puntos ni espacios"
                                className="width-100-per"
                                style={{ textAlign: 'left' }}
                                name="manualCuit"
                                onChange={handleManualCuitChange}
                                value={manualCuit}
                                error={manualCuitError}
                                helperText={manualCuitError && 'Debés ingresar un CUIT válido'}
                                InputProps={{
                                    inputComponent: CuitMaskCustom,
                                }}
                                required="true"
                                type="tel"
                            />
                        </Grid>
                        <Grid item xs={12} sm={8} lg={8} className="mt-4">
                            <div className="mt-2"></div>
                            <TextField
                                id="standard-basic"
                                label="Ingresá tu nombre"
                                className="width-100-per"
                                style={{ textAlign: 'left' }}
                                name="manualFirstName"
                                onChange={handleFirstNameChange}
                                value={manualFirstName}
                                required={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8} lg={8} className="mt-4">
                            <div className="mt-2"></div>
                            <TextField
                                id="standard-basic"
                                label="Ingresá tu apellido"
                                className="width-100-per"
                                style={{ textAlign: 'left' }}
                                name="manualLastName"
                                onChange={handleLastNameChange}
                                value={manualLastName}
                                required={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8} lg={8} className="mt-4">
                            <div className="mt-2"></div>
                            <CustomDatePicker
                                style={{ marginTop: '0%', width: '100%' }}
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="datePicker"
                                label="Fecha de nacimiento"
                                value={birthDate}
                                errorHelperText="Completá la fecha con el formato dd/mm/aaaa"
                                onChange={(date) => handleDateChange(date)}
                                required={true}
                                KeyboardButtonProps={{
                                    'aria-label': 'Fecha',
                                }}></CustomDatePicker>
                        </Grid>
                    </Grid>
                </div>
            )}
        </>
    ) : null;

    const validateButtonSexCuit = optionCuitVisible ? (
        <>
            <div className="mt-2"> </div>
            <Grid container direction="row" justifyContent="center" alignItems="center" xs={12}>
                <Grid item xs={12} className="padding-5">
                    <Button
                        type="submit"
                        className="flex width-100-per "
                        variant="outlined"
                        color="primary"
                        disabled={(!manualCuit || !manualFirstName || !manualLastName || !birthDate) && cuits.length < 1}>
                        Confirmar
                    </Button>
                </Grid>
            </Grid>
        </>
    ) : null;

    const checkEmail = checkEmailVisible ? (
        <CheckEmail showEmailInput={!customerDataCompleted} onComplete={handleCompleteEmail} onEmailChanged={handleEmailChanged} />
    ) : null;

    const checkPhone = checkPhoneVisible ? <CheckPhone sendCode={false} handlePinSent={handlePinSent} /> : null;

    const checkAcceptTerms = checkTermsVisible ? (
        <Grid item xs={12}>
            <AcceptGeneralTermsCheckbox onChange={handleAcceptTermsChange} />
            <Hidden mdUp>
                <Box m={3} />
            </Hidden>
        </Grid>
    ) : null;

    return (
        <Grid container>
            <Loader loading={loading} />
            <Grid item container direction="row" justifyContent="flex-start" className="">
                <Grid item xs={12} sm={12} md={12} lg={12} className="">
                    <form onSubmit={handleFormSubmitted} className="width-100-per">
                        <Grid container direction="row" align="center" className="mt-2">
                            {!checkPhoneVisible && !checkEmailVisible && !alternativeCuit && (
                                <>
                                    <Grid item xs={12} sm={12} lg={12}>
                                        <TextField
                                            id="standard-basic"
                                            label="Ingresá tu DNI sin puntos ni espacios"
                                            className="width-100-per"
                                            style={{ textAlign: 'left' }}
                                            name="dni"
                                            onChange={handleDNIChange}
                                            value={dni}
                                            error={dniError}
                                            helperText={dniError && 'Debés ingresar un DNI válido'}
                                            InputProps={{
                                                inputComponent: DniMaskCustom,
                                            }}
                                            type="tel"
                                        />
                                        <Hidden mdUp>
                                            <Box m={3} />
                                        </Hidden>
                                    </Grid>
                                    {!optionCuitVisible && (
                                        <>
                                            <Hidden mdUp>
                                                <Box m={3} />
                                            </Hidden>
                                            <Grid item xs={12} sm={12} lg={12} className="fixed-bottom-button button-container">
                                                <Button
                                                    style={styles.container(matches)}
                                                    className="dni-container ml-0"
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleValidateDNIClicked}
                                                    disabled={!dni}>
                                                    Siguiente
                                                </Button>
                                                <Hidden mdUp>
                                                    <Box m={3} style={{ marginBottom: '60px' }} />
                                                </Hidden>
                                            </Grid>
                                        </>
                                    )}
                                </>
                            )}

                            {(checkEmailVisible || alternativeCuit) && (
                                <>
                                    <Box>
                                        <strong className="fs-14 fw-600">DNI {dni} - </strong>
                                        <a href="#" className="link fs-14 fw-400 color-link-blue" onClick={handleChangeDNIClicked}>
                                            Editar
                                        </a>
                                    </Box>
                                </>
                            )}
                            <ShowToaster open={open} textToShow={message} type="error" handleToasterClose={handleToasterClose} />
                        </Grid>

                        <Grid item className="">
                            <Box className="mt-1 "></Box>
                            {optionCuit}
                        </Grid>

                        <Grid item className="mt-2">
                            {validateButtonSexCuit}
                        </Grid>

                        <Grid item className="">
                            {checkEmail}
                        </Grid>

                        <Grid item className="">
                            {checkPhone}
                        </Grid>

                        {!requestButtonVisible && <div className="mb-footer-2"></div>}
                        {requestButtonVisible && (
                            <Grid container direction="row" justify="center" alignItems="center">
                                {checkAcceptTerms}
                                <Hidden smUp>
                                    <Box m={6} />
                                </Hidden>

                                <Grid item xs={12} sm={10}>
                                    {width < WIDTH_BREAKPOINT && (
                                        <div className="startButton center">
                                            <div className="center mt-05 mt-20-web mb-4 width-100-per">
                                                <Button
                                                    className="flex center width-90-per"
                                                    variant="contained"
                                                    color="primary"
                                                    type="submit"
                                                    disabled={isStartButtonDisabled()}>
                                                    Verificar mis datos
                                                </Button>
                                            </div>
                                        </div>
                                    )}

                                    {width > WIDTH_BREAKPOINT && (
                                        <Button
                                            className="flex center width-100-per mt-20-web mb-20-web mt-3"
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            disabled={isStartButtonDisabled()}>
                                            Verificar mis datos
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        )}
                    </form>
                </Grid>
                <Hidden xsDown>
                    <Grid item sm={1}></Grid>
                </Hidden>
            </Grid>
        </Grid>
    );
}
