import { Box, Button, Grid, Hidden } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Toaster from '../components/alerts/Toaster';
import useCurrentTask from '../components/bpm/useCurrentTask';
import ContinueMobile from '../components/deviceCommunication/continueMobile';
import Loader from '../components/loader/Loader';
import UserInputs from '../components/userInputs/UserInputs';
import creditCardRequestService from '../services/creditCardRequest.service';
import eventTrackingService from '../services/eventtracking.service';
import './../styles/components/customerDataInput.scss';
import MacroStepPage from './shared/MacroStepPage';

export const SelectOfficeForAccountOpening = (props) => {
    const { hashKey } = props.match.params;
    const { taskDefinitionKey } = props;
    const [loanRequest, setLoanRequest] = useState(null);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const task = useCurrentTask(hashKey, taskDefinitionKey);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const { handleSubmit, ...methods } = useForm();

    useEffect(() => {
        eventTrackingService.trackContentViewEvent('/tarjetas-credito/seleccion-sucursal');
    }, []);

    useEffect(() => {
        async function getLoanRequest() {
            try {
                setLoading(true);
                setLoanRequest((await creditCardRequestService.getCreditCardRequest(hashKey)).data);
            } finally {
                setLoading(false);
            }
        }

        getLoanRequest();
    }, [hashKey]);

    const handleNextPressed = async (formValues) => {
        try {
            await creditCardRequestService.setOfficeDataForAccountOpening(hashKey, formValues);
            task.completeTask();
            eventTrackingService.trackEventWithData('tarjetas_seleccion_sucursal', { sucursal: formValues.PtllaSucursalApertura }, null);
        } catch (error) {
            if (error.response && error.response.data) {
                const { properties, errorCode } = error.response.data;
                const { fieldsToCompleted } = properties;
                if (errorCode === 'ALL_FIELDS_REQUIERED_MUST_BE_COMPLETED') {
                    var msg = t(`WORKFLOW_ERROR.${errorCode}.DESCRIPTION`);
                    fieldsToCompleted.map((field) => (msg += ' ' + t(`FIELDS.${field}`)));
                    setMessage(msg);
                    setOpen(true);
                }
            }
        }
    };

    const handleComplete = () => {};

    const ShowToaster = (props) => {
        return <Toaster elevation={6} variant="filled" {...props} />;
    };

    const handleToasterClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Loader loading={loading} />
            <MacroStepPage
                headerLead="Tu tarjeta de crédito está casi lista"
                headerTitle="Seleccioná la sucursal en la que querés operar"
                hashKey={hashKey}
                task={task}
                taskDefinitionKey={taskDefinitionKey}
                backToText="Completar tus datos personales"
                hideStepper={true}
                stepNumber={3}
                totalSteps={4}>
                {!isMobile ? (
                    <ContinueMobile hashKey={hashKey} />
                ) : (
                    <FormProvider {...methods}>
                        <form onSubmit={handleSubmit(handleNextPressed)}>
                            <Grid container>
                                {loanRequest && (
                                    <UserInputs
                                        stepName={taskDefinitionKey}
                                        hashKey={loanRequest.hashKey}
                                        onComplete={handleComplete}
                                        normalizedAddress={loanRequest.customerData.normalizationStatus === 'CO'}
                                    />
                                )}
                            </Grid>

                            <Grid container direction="row" justify="center" alignItems="center" xs={12}>
                                <Hidden smUp>
                                    <Grid item xs={12}>
                                        <Box className="mt-5"></Box>
                                    </Grid>
                                </Hidden>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <div className="validationIdentityButton center mt-20-web">
                                        <Button
                                            className="width-100-per width-90-res"
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            disabled={loading}>
                                            Siguiente
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                            <ShowToaster open={open} textToShow={message} type="error" handleToasterClose={handleToasterClose} />
                        </form>
                    </FormProvider>
                )}
            </MacroStepPage>
        </>
    );
};
