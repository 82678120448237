import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import useCurrentTask from '../components/bpm/useCurrentTask';
import ContinueMobile from '../components/deviceCommunication/continueMobile';
import { ManualDocumentUploader } from '../components/documentsUpload/ManualDocumentUploader';
import documentService from '../services/document.service';
import eventTrackingService from '../services/eventtracking.service';
import '../styles/components/documentsUpload.scss';
import redX from './../assets/bigX.jpg';
import MacroStepPage from './shared/MacroStepPage';

import mobilednifrente from '../assets/dni-frente.svg';
import Loader from '../components/loader/Loader';
import useCreditCardRequest from '../hooks/useCreditCardRequest';
import { DocumentsUploadHints } from './shared/DocumentsUploadHints';

import imgDNI from '../assets/dni.svg';
import imgDniBack from '../assets/dni_back.svg';
import imgSelfie from '../assets/selfie.svg';

export const DocumentsUploadFront = (props) => {
    const { hashKey } = props.match.params;
    const { creditCardRequest } = useCreditCardRequest(hashKey);
    const [hasValidationErrors, setHasValidationErrors] = useState(false);
    const [errorCode, setErrorCode] = useState(null);
    const [documents, setDocuments] = useState(undefined);
    const [uploadMethod, setUploadMethod] = useState(null);
    const [uploadStarted, setUploadStarted] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const analyticsStep = 6;
    const [cardMigration, setCardMigration] = useState(false);
    const task = useCurrentTask(hashKey, props.taskDefinitionKey);

    useEffect(() => {
        if (!uploadStarted) {
            eventTrackingService.trackContentViewEvent('/tarjetas-credito/inicio-biometria');
        }
    }, [uploadStarted]);

    useEffect(() => {
        async function getDocuments() {
            try {
                setLoading(true);
                setDocuments((await documentService.getDocuments(hashKey, 'documentsUpload')).data);
                setCardMigration(creditCardRequest.metadata.Origin === 'macro-migration-credit-card');
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        }

        if (creditCardRequest) {
            getDocuments();
        }
    }, [creditCardRequest]);

    const onDocumentUploaded = (imgSrcUploaded, uploadMethod) => {
        eventTrackingService.trackEvent('dni frente', uploadMethod, null, analyticsStep);
        setUploadMethod(uploadMethod);
    };

    const handleNext = async () => {
        try {
            eventTrackingService.trackEvent('dni frente completed', uploadMethod, null, analyticsStep);
            setLoading(true);
            await task.completeTask();
        } catch (error) {
            setHasValidationErrors(true);
            if (error.response) {
                if (error.response.data) {
                    setErrorCode(error.response.data.errorCode);
                } else {
                    console.error('Error: ' + error);
                }
            }
        } finally {
            setLoading(false);
        }
    };

    const handleRestartFaceValidation = () => {
        setHasValidationErrors(false);
    };

    const handleStartUploadDocs = () => {
        setUploadStarted(true);
    };

    const ShowValidationError = () => {
        return (
            <Grid container direction="row" justify="center" alignItems="center">
                <Grid container item xs={12} direction="column" justify="center" alignItems="center">
                    <div className="mt-5"></div>
                    <img src={redX} alt=""></img>
                    <div className="mt-3"></div>
                    <p className="fs-18 fw-500">Hubo un problema! </p>
                    <div className="mt-2"></div>
                    <p>{t('RENAPER_VALIDATION_ERROR.' + errorCode + '.DESCRIPTION', 'Alguna de tus fotos no fueron validadas correctamente')}</p>
                    <div className="mt-2"></div>
                    <Grid item xs={12} lg={4}>
                        <Button onClick={handleRestartFaceValidation} className="width-90-per" variant="contained" color="primary">
                            Siguiente
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    const handleClickOpenPopup = () => {
        setOpenPopup(true);
    };

    const handleClosePopup = () => {
        setOpenPopup(false);
    };

    const whyPopup = useMemo(
        () => (
            <>
                <Dialog onClose={handleClosePopup} aria-labelledby="help-dialog-title" open={openPopup}>
                    {/* Dialogo de condiciones del Servicio seleccionado */}
                    <DialogTitle id="help-dialog-title" onClose={handleClosePopup} className="dialog-title-conditions">
                        ¿Por qué pedimos tus fotos?
                    </DialogTitle>
                    <DialogContent dividers>
                        <p className="dialog-text">
                            Por tu seguridad, necesitamos que saques fotos de tu DNI y rostro para verificar que sos vos quien está solicitando la
                            tarjeta.
                        </p>
                        <br />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={handleClosePopup} color="primary" style={{ width: '100%' }}>
                            Entendido
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        ),
        [openPopup, t]
    );

    return !uploadStarted ? (
        <>
            <Loader loading={loading} />
            <MacroStepPage
                headerLead={
                    isMobile ? (cardMigration ? 'Estás muy cerca de tener tu nueva tarjeta' : 'Estás muy cerca de tener tu tarjeta de crédito') : ''
                }
                headerLeadClassName="fw-600"
                headerTitle={isMobile ? 'Vamos a validar tu identidad' : ''}
                hashKey={hashKey}
                task={task}
                taskDefinitionKey={props.taskDefinitionKey}
                hideStepper={true}
                stepNumber={1}
                totalSteps={4}
                hideQuestions
                className="mt-1">
                {isMobile ? (
                    <>
                        <Grid item container style={{ zIndex: 100 }} xs={12} direction="row" justify="center">
                            <Grid item xs={12} md={6}>
                                <p className="mt-2">Vas a necesitar: </p>
                            </Grid>
                            <Grid item xs={12} md={6} justify="center">
                                <p className="center mt-2">Sacarle una foto al frente y dorso de tu DNI</p>
                            </Grid>
                            <Grid item xs={12} md={6} justify="center">
                                <div className="center mt-2">
                                    <img src={imgDNI} alt="" style={{ paddingRight: '20px' }} />
                                    <img src={imgDniBack} alt="" style={{ paddingLeft: '20px' }} />
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <Box m={3} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <p className="center mt-2">Y hacer una prueba de vida</p>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className="center mt-2">
                                    <img src={imgSelfie} alt="" />
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <Box m={3} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <p className="text-align-center mt-2">
                                    Las fotos y datos son para uso exclusivo del banco y no se comparten con terceros.{' '}
                                </p>
                            </Grid>
                            <Grid item xs={12}>
                                <Box m={3} />
                            </Grid>
                            <Button varian="text" onClick={handleClickOpenPopup} className="mt-3 link-text-button link-secondary-color">
                                ¿Por qué pedimos estas fotos?
                            </Button>
                        </Grid>
                        {whyPopup}
                        <Grid item>
                            <Box style={{ height: '120px' }} />
                        </Grid>
                        <Grid item container className="center mt-2 mb-footer validationIdentityButton center">
                            <Button onClick={handleStartUploadDocs} variant="contained" color="primary" className="width-100-per width-90-res">
                                Comenzar
                            </Button>
                        </Grid>
                    </>
                ) : (
                    <ContinueMobile hashKey={hashKey} />
                )}
            </MacroStepPage>
        </>
    ) : (
        <>
            <Loader loading={loading} />
            <MacroStepPage
                headerLead={
                    isMobile ? (cardMigration ? 'Estás muy cerca de tener tu nueva tarjeta' : 'Estás muy cerca de tener tu tarjeta de crédito') : ''
                }
                headerLeadClassName="fw-600"
                headerTitle={isMobile ? 'Sacale una foto a tu DNI' : ''}
                hashKey={hashKey}
                task={task}
                backToText="Tipo DNI"
                taskDefinitionKey={props.taskDefinitionKey}
                hideStepper={true}
                stepNumber={1}
                totalSteps={4}
                hideQuestions
                className="mt-1">
                {isMobile ? (
                    creditCardRequest && documents && !hasValidationErrors ? (
                        <>
                            <Grid item container style={{ zIndex: 100 }} xs={12} direction="row" justify="center">
                                <ManualDocumentUploader
                                    activeStep={0}
                                    title="DNI frente"
                                    hintInfo={<DocumentsUploadHints />}
                                    hashKey={creditCardRequest.hashKey}
                                    document={documents[0]}
                                    onDocumentUploaded={onDocumentUploaded}
                                    onHandleNext={handleNext}
                                    img={mobilednifrente}
                                />
                            </Grid>
                        </>
                    ) : (
                        hasValidationErrors && <ShowValidationError />
                    )
                ) : (
                    <ContinueMobile hashKey={hashKey} />
                )}
            </MacroStepPage>
        </>
    );
};
