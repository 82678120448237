import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Hidden } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import excl from '../assets/exclamacion.svg';
import Toaster from '../components/alerts/Toaster';
import useCurrentTask from '../components/bpm/useCurrentTask';
import Loader from '../components/loader/Loader';
import MainButton from '../components/MainButton';
import AcceptCardCancellation from '../components/userInputs/AcceptCardCancellation';
import { numberToCurrencyText } from '../components/utils/numberUtils';
import { obfuscateFirstDigits } from '../components/utils/obfuscate';
import {
    footnote,
    montoBonificado100,
    montoBonificado50,
    precioPaquete,
    showMigrationTerms,
    showTerms,
} from '../components/utils/termsAndConditionsUtils';
import useCreditCardRequest from '../hooks/useCreditCardRequest';
import eventTrackingService from '../services/eventtracking.service';
import offerService from '../services/offer.service';
import productService from '../services/product.service';
import '../styles/components/offerSelection.scss';
import MacroStepPage from './shared/MacroStepPage';

export const OfferSelection = (props) => {
    const { hashKey } = props.match.params;
    const [availableProducts, setAvailableProducts] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedProductDetails, setSelectedProductDetails] = useState(null);
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(null);
    const [imgCard, setImgCard] = useState();
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();
    const [openPopup, setOpenPopup] = useState(false);
    const [openTCPopup, setOpenTCPopup] = useState(false);
    const task = useCurrentTask(hashKey, props.taskDefinitionKey);
    const { creditCardRequest } = useCreditCardRequest(hashKey);
    const [termsVisible, setTermsVisible] = useState(false);
    const [cardCancellationAccepted, setCardCancellationAccepted] = useState(false);
    const [cardMigration, setCardMigration] = useState(false);
    const analyticsStep = 9; // offerSelection (ref useCurrentTask)

    useEffect(() => {
        eventTrackingService.trackContentViewEvent('/tarjetas-credito/oferta-disponible');
    }, []);

    useEffect(() => {
        if (creditCardRequest) {
            setCardMigration(creditCardRequest.metadata.Origin === 'macro-migration-credit-card');
            setAvailableProducts(creditCardRequest.offerOptions);
            setSelectedProduct(creditCardRequest.offerOptions[0]);
        }
    }, [creditCardRequest]);

    const getImageCard = () => {
        const imgSrc = require(`./../assets/tc_paquetes/${selectedProduct.productCode.toLowerCase()}.png`);
        setImgCard(<img src={imgSrc} alt="" className="credit-card-img" />);
    };

    useEffect(() => {
        async function getProductDetails(productCode) {
            try {
                const selProd = await productService.getProductByModel(productCode);
                setSelectedProductDetails(selProd.data.productDetails);
            } catch (error) {
                console.error(error.response);
            }
        }

        if (selectedProduct && selectedProduct.productCode) {
            getImageCard();
            getProductDetails(selectedProduct.productCode);
        }
    }, [selectedProduct]);

    useEffect(() => {
        if (selectedProductDetails) {
            setTermsVisible(true);
            eventTrackingService.trackEventWithData(
                'tc_oferta_disponible',
                {
                    monto_oferta: selectedProduct.OfferLimit,
                    tipo_tarjeta: cardType,
                },
                analyticsStep
            );
        }
    }, [selectedProductDetails]);

    async function saveSelectedProduct() {
        try {
            setLoading(true);
            await offerService.setSelectedProduct(hashKey, selectedProduct);
            setLoading(false);
            task.completeTask();
        } catch (error) {
            console.error(error);
            setLoading(false);
            if (error.response) {
                const { errorCode } = error.response.data;
                setMessage(t(`WORKFLOW_ERROR.${errorCode}.DESCRIPTION`));
                setOpen(true);
            } else {
                console.error(error);
            }
        }
    }

    const handleNextClicked = (event) => {
        event.preventDefault();
        saveSelectedProduct();
    };

    const ShowToaster = (props) => {
        return <Toaster elevation={6} variant="filled" {...props} />;
    };

    const handleToasterClose = () => {
        setOpen(false);
    };
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const logo = matches ? <img className="logoAdm" src={excl} alt="" /> : null;

    const handleClickOpenPopup = () => {
        setOpenPopup(true);
    };

    const handleClosePopup = () => {
        setOpenPopup(false);
    };

    const handleOpenTermsPopup = () => {
        setOpenTCPopup(true);
    };

    const handleCloseTermsPopup = () => {
        setOpenTCPopup(false);
    };

    const packageName = selectedProduct ? `${t('CREDIT_CARD_DESCRIPTION.' + selectedProduct.productCode + '.PACKAGE_SHORT')}` : null;

    const clientType = selectedProduct ? `${t('CREDIT_CARD_DESCRIPTION.' + selectedProduct.productCode + '.CLIENT')}` : null;

    const cardType = selectedProduct ? `${t('CREDIT_CARD_DESCRIPTION.' + selectedProduct.productCode + '.NAME')}` : null;

    const availableAmount = selectedProduct ? numberToCurrencyText(selectedProduct.OfferLimit) : null;

    const firstLineTitle = creditCardRequest && cardMigration ? 'Pensamos en grande' : '¡Buenas noticias!';

    const conditionsPopup = useMemo(
        () => (
            <>
                {creditCardRequest && selectedProduct && termsVisible && (
                    <Dialog onClose={handleClosePopup} aria-labelledby="help-dialog-title" open={openPopup}>
                        {/* Dialogo de condiciones del servicio seleccionado */}
                        <DialogTitle id="help-dialog-title" onClose={handleClosePopup} className="dialog-title-conditions">
                            Conocé las condiciones de bonificación de tu servicio
                        </DialogTitle>
                        <DialogContent dividers>
                            <p className="dialog-text">
                                Los primeros 12 meses son 100% bonificados y a partir del mes trece empezarás a pagar un valor de{' '}
                                {precioPaquete(selectedProductDetails)} (Costo de referencia a la fecha).
                            </p>
                            <p className="dialog-text">
                                Si cumplís con alguna de las siguientes condiciones podés continuar con el mantenimiento mensual sin costo:
                            </p>
                            <div>
                                <div className="condition-box">
                                    <p>
                                        Adhiriendo 3 Débitos Automáticos a tu Tarjeta de Crédito accedés a una bonificación de 3 meses adicionales,
                                        del 100% de tu servicio.
                                    </p>
                                </div>
                                <div className="condition-box">
                                    <p>
                                        Con un saldo promedio de {montoBonificado100(selectedProductDetails)} obtenés bonificación del 100% de tu
                                        servicio. (1)
                                    </p>
                                </div>
                                <div className="condition-box">
                                    <p>
                                        Con un saldo promedio de {montoBonificado50(selectedProductDetails)} obtenés bonificación del 50% de tu
                                        servicio. (1)
                                    </p>
                                </div>
                            </div>
                            <p className="disclaimer-note justify-terms" gutterBottom>
                                {footnote(selectedProduct)}
                            </p>
                            <br />
                            <hr />
                            <br />
                            <p className="dialog-title-conditions">Términos Legales</p>
                            <br />
                            {showTerms(selectedProduct, selectedProductDetails, packageName)}
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" onClick={handleClosePopup} color="primary" className="size-100">
                                Entendido
                            </Button>
                        </DialogActions>
                    </Dialog>
                )}
            </>
        ),
        [creditCardRequest, openPopup, selectedProduct, t]
    );

    const handleAcceptTermsChange = (accepted, termsId) => {
        setCardCancellationAccepted(accepted);
    };

    const handleAcceptCardCancellationClicked = (accepted, termsId) => {
        setCardCancellationAccepted(accepted);
    };

    const mainButtonSection = useMemo(
        () => (
            <div className="offerSelection">
                <Hidden mdUp>
                    {creditCardRequest && cardMigration && (
                        <Grid item xs={12}>
                            <AcceptCardCancellation onChange={handleAcceptTermsChange} onContractLinkClicked={handleAcceptCardCancellationClicked} />
                        </Grid>
                    )}
                </Hidden>

                <Hidden smUp>
                    <Grid item className="text-align-center size-100">
                        <p className="tc-text">
                            Ver{' '}
                            <Button varian="text" onClick={handleOpenTermsPopup} color="secondary" className="link-text-button">
                                Términos Legales
                            </Button>
                        </p>
                    </Grid>
                </Hidden>

                {/* Términos del servicio */}
                <Dialog onClose={handleCloseTermsPopup} aria-labelledby="help-dialog-title" open={openTCPopup}>
                    <DialogTitle id="help-dialog-title" onClose={handleCloseTermsPopup} className="dialog-title-conditions">
                        Términos legales
                    </DialogTitle>
                    <DialogContent dividers>
                        {creditCardRequest && selectedProduct && cardMigration ? (
                            showMigrationTerms()
                        ) : (
                            <p className="disclaimer-note">
                                <p className="disclaimer-note justify-terms" gutterBottom>
                                    Cartera de Consumo. Promoción de aplicación exclusiva para la persona a la cual se dirige la presente
                                    comunicación. Los beneficios no podrán ser cedidos y/o transferidos a favor de terceros.
                                    <br />
                                    (1) La aceptación como cliente {clientType}, el otorgamiento de tarjeta/s, cuenta/s, asistencia crediticia y demás
                                    beneficios, se encuentra sujeto a aprobación crediticia y condiciones de contratación de Banco Macro. En caso de
                                    no aceptar esta oferta no implicara ningún cargo ni responsabilidad de su parte. La tarjeta de crédito preaprobada
                                    es válida para quienes contraten o registren en nuestra entidad un servicio {clientType} asociada a la tarjeta de
                                    crédito ofrecida. Sujeta a condiciones de contratación, otorgamiento y aprobación crediticia por Banco Macro S.A.
                                    al momento de ser efectivamente solicitada. El monto pre aprobado es válido para solicitudes de los productos que
                                    se realicen en el día de la fecha. En caso de no aceptar esta oferta, no implicará ningún cargo ni responsabilidad
                                    de su parte. Consulte condiciones, montos, plazos, cuotas, tasas de interés, costo financiero total, ejemplos
                                    representativos de la financiación y toda información adicional de los productos en macro.com.ar o acercándose a
                                    la sucursal designada en la presente promoción. <br />
                                    (2) Bonificación del 100% en la comisión por servicio mensual por un plazo de 12 meses contados desde el alta del
                                    servicio {clientType}, luego el cliente deberá abonar el costo del mantenimiento mensual que será el vigente al
                                    momento de finalizar la bonificación. Consulte los cargos de comisiones en macro.com.ar
                                </p>
                            </p>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={handleCloseTermsPopup} color="primary" className="size-100">
                            Entendido
                        </Button>
                    </DialogActions>
                </Dialog>
                <Grid item container className="center-responsive">
                    <Grid item xs={10} sm={7} md={6} lg={4}>
                        <MainButton
                            style={{ width: '100%', margin: '0', fontSize: '20px' }}
                            onClick={handleNextClicked}
                            disabled={creditCardRequest && cardMigration ? !cardCancellationAccepted : false}
                            fixedBottomMobile>
                            {creditCardRequest && cardMigration ? 'Solicitar ahora' : '¡Quiero mi Tarjeta Macro!'}
                        </MainButton>
                        <Hidden xsDown>
                            <p className="tc-text mt-1">
                                Ver{' '}
                                <Button varian="text" onClick={handleOpenTermsPopup} color="secondary" className="link-text-button link-gray">
                                    Términos Legales
                                </Button>
                            </p>
                        </Hidden>
                    </Grid>
                </Grid>
            </div>
        ),
        [handleNextClicked, openTCPopup]
    );

    return (
        <MacroStepPage
            headerLead=""
            headerTitle={``}
            hashKey={hashKey}
            task={task}
            taskDefinitionKey={props.taskDefinitionKey}
            hideStepper={true}
            hideQuestions
            stepNumber={2}
            totalSteps={4}
            resetStyles
            noBackground>
            {creditCardRequest && selectedProduct && (
                <>
                    <Loader loading={loading} />
                    <Grid>
                        <div className="CustomSizeOffer">
                            <Grid container direction="column" justifyContent="center">
                                <Grid item container direction="row" className="mb-1" style={{ zIndex: '1' }}>
                                    <Grid
                                        item
                                        container
                                        xs={12}
                                        md={12}
                                        lg={12}
                                        alignContent="flex-start"
                                        alignItems="flex-start"
                                        className="banner-offer-box">
                                        {imgCard && (
                                            <Grid item xs={12} md={6} className="card-container">
                                                {imgCard}
                                            </Grid>
                                        )}
                                        <Grid item xs={12} md={6}>
                                            <>
                                                <h1 className="fs-22">{firstLineTitle}</h1>
                                                <p className="banner-highlight">
                                                    Tenés disponible una <b className="fw-700"> tarjeta {cardType}</b> con un límite de{' '}
                                                    <b className="fw-700">{availableAmount}</b>{' '}
                                                </p>
                                            </>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            {cardMigration ? (
                                                <>
                                                    <p className="disclaimer-text-migration">
                                                        Para pedir esta tarjeta deberás deberás dar de baja tu tarjeta{' '}
                                                        <b>{creditCardRequest.metadata.actualCard}</b> terminada en{' '}
                                                        <b>{obfuscateFirstDigits(creditCardRequest.metadata.cardNumber)}.</b>
                                                    </p>
                                                    <p className="disclaimer-text-migration">
                                                        La baja será efectiva una vez que tengas tu nueva tarjeta. Luego de la baja no podrás usarla y
                                                        los débitos automáticos que tengas deberás cargarlos a tu nueva tarjeta.
                                                    </p>
                                                </>
                                            ) : (
                                                <>
                                                    <p className="disclaimer-text">
                                                        100% bonificado por 12 meses adquiriendo el servicio{' '}
                                                        <b>{t('CREDIT_CARD_DESCRIPTION.' + selectedProduct.productCode + '.PACKAGE')}*</b> <br />* A
                                                        partir del mes trece podés mantener la bonificación con alguna de las siguientes{' '}
                                                        <Button
                                                            variant="text"
                                                            onClick={handleClickOpenPopup}
                                                            color="secondary"
                                                            className="link-text-button link-white">
                                                            condiciones
                                                        </Button>
                                                    </p>
                                                </>
                                            )}
                                        </Grid>
                                        {conditionsPopup}

                                        <Hidden xsDown>
                                            <Grid className="" container direction="row">
                                                {mainButtonSection}
                                            </Grid>
                                        </Hidden>

                                        <Grid item>
                                            <Box style={{ height: '240px' }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Hidden smUp>
                                    <Grid item>{mainButtonSection}</Grid>
                                </Hidden>
                                <Grid item>
                                    <ShowToaster open={open} textToShow={message} type="error" handleToasterClose={handleToasterClose} />
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </>
            )}
        </MacroStepPage>
    );
};
